import BaseApi from './BaseApi';
import { BASE_URL } from 'types/config';
import { AxiosPromise } from 'axios';
import { ITenant, ITenantDeviceOption, ITenantOptions } from 'types/tenant';

export default class TenantApi extends BaseApi {
  private baseURL: string = BASE_URL;
  constructor() {
    super();
  }

  public getAll(): AxiosPromise<ITenant[]> {
    return this.get(`${this.baseURL}/tenant`);
  }
  public getOptions(): AxiosPromise<ITenantOptions> {
    return this.get(`${this.baseURL}/tenant/options`);
  }

  public add(body: object = {}): AxiosPromise<ITenant> {
    return this.post(`${this.baseURL}/tenant`, body);
  }

  public devices(id: string): AxiosPromise<ITenantDeviceOption[]> {
    return this.get(`${this.baseURL}/tenant/${id}/devices`);
  }

  public detail(id: string): AxiosPromise<ITenant> {
    return this.get(`${this.baseURL}/tenant/${id}`);
  }

  public remove(id: string): AxiosPromise<void> {
    return this.delete(`${this.baseURL}/tenant/${id}`);
  }

  public update(id: string, body: object = {}): AxiosPromise<ITenant> {
    return this.patch(`${this.baseURL}/tenant/${id}`, body);
  }

  public checkMailSettings(body: object = {}): AxiosPromise<string> {
    return this.post(`${this.baseURL}/mail/check`, body);
  }
}
