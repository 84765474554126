<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useUserStore } from '@/store/user';

import { Roles } from 'types/roles';

import PageHeading from '@/components/PageHeading/PageHeading.vue';
import BaseModal from '@/components/BaseModal/BaseModal.vue';
import NoData from '@/components/NoData/NoData.vue';

import DataTable from 'primevue/datatable';
import Dropdown from 'primevue/dropdown';
import Column from 'primevue/column';
import { FilterMatchMode } from 'primevue/api';

import useMessages from '@/composables/messages';
import { MessageType } from 'types/message';
const { addToast } = useMessages();

const userStore = useUserStore();
await userStore.getAll();

const route = useRoute();
const router = useRouter();
const pageName = route.name;

const openModal = ref(false);
const dataModalDelete = ref<{ id: string; name: string }>({
  id: '',
  name: '',
});

const onClickDelete = (data: { id: string; name: string }) => {
  dataModalDelete.value = data;
  openModal.value = true;
};

const onHandleDelete = async () => {
  if (!dataModalDelete.value.id) return;
  const isSuccess: boolean = await userStore.deleteUser(dataModalDelete.value.id);
  dataModalDelete.value = { id: '', name: '' };
  openModal.value = false;
  userStore.getAll();

  if (isSuccess) {
    addToast({
      type: MessageType.Success,
      content: `Benutzer löschen erfolgreich`,
    });
  } else {
    addToast({
      type: MessageType.Error,
      content: `Benutzer löschen fehlgeschlagen`,
    });
  }
};

const listUser = computed(() => userStore.listAllUsers);

const loading = ref(false);
const refreshListData = async () => {
  loading.value = true;
  await userStore.getAll();
  loading.value = false;
};

// object containing settings for datatable filter
const filters = ref({
  role: { value: null, matchMode: FilterMatchMode.EQUALS },
  'tenant.name': { value: null, matchMode: FilterMatchMode.EQUALS },
});

// dropdown data for roles filter
const rolesFilterDropdownData = ref(
  Object.entries(Roles).map(([key, value]) => {
    return {
      label: value,
      value: key,
    };
  }),
);

// dropdown data for tenants filter
const tenantFilterDropdownData = ref<{ label: string; value: string }[]>([]);
const tenantNames: string[] = [];

listUser.value
  .filter((u) => u.tenant)
  .forEach((value) => {
    if (!tenantNames.includes(value.tenant?.name)) {
      tenantFilterDropdownData.value.push({
        label: value.tenant?.name,
        value: value.tenant?.name,
      });
      tenantNames.push(value.tenant?.name);
    }
  });
</script>
<template>
  <div class="page-content w-full">
    <PageHeading
      class="mb-5"
      :title="pageName"
      :button="true"
      to="/admin/user/add"
      :refresh="true"
      :loading="loading"
      @click-refresh="refreshListData"
    />
    <div class="card">
      <DataTable
        v-model:filters="filters"
        :value="listUser"
        responsive-layout="scroll"
        :loading="loading"
        filter-display="menu"
      >
        <Column field="user" header="Benutzer" :sortable="true" sort-field="firstName">
          <template #body="slotProps">
            <span>{{ slotProps.data.firstName }} {{ slotProps.data.lastName }}</span>
          </template>
        </Column>
        <!-- <Column field="department" header="Abteilung" :sortable="true">
          <template #body="slotProps">
            <span>{{ slotProps.data.department }}</span>
          </template>
        </Column>-->
        <Column
          field="role"
          header="Rolle"
          :sortable="true"
          sort-field="role"
          filter-field="role"
          :show-filter-match-modes="false"
          :show-filter-operator="false"
        >
          <template #body="slotProps">
            <span>{{ Roles[slotProps.data.role] }}</span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              :options="rolesFilterDropdownData"
              placeholder="Alle"
              class="p-column-filter"
              option-label="label"
              option-value="value"
              :show-clear="true"
              @change="filterCallback"
            />
          </template>
          <template #filterapply="{}"></template>
          <template #filterclear="{}"></template>
        </Column>
        <Column
          field="tenant"
          header="Tenant"
          :sortable="true"
          sort-field="tenant.name"
          filter-field="tenant.name"
          :show-filter-match-modes="false"
          :show-filter-operator="false"
        >
          <template #body="slotProps">
            <span>{{ slotProps.data.tenant?.name }}</span>
          </template>
          <template #filter="{ filterModel, filterCallback }">
            <Dropdown
              v-model="filterModel.value"
              :options="tenantFilterDropdownData"
              placeholder="Alle"
              class="p-column-filter"
              option-label="label"
              option-value="value"
              :show-clear="true"
              @change="filterCallback"
            />
          </template>
          <template #filterapply="{}"></template>
          <template #filterclear="{}"></template>
        </Column>
        <Column field="active">
          <template #body="slotProps">
            <i
              class="pi pi-cog text-l cursor-pointer hover:text-first p-2 mr-2"
              :slotProps="slotProps"
              @click="router.push({ name: 'Benutzer bearbeiten', params: { id: slotProps.data.id } })"
            ></i>
            <i
              class="pi pi-trash text-l cursor-pointer text-red hover:text-red p-2"
              :slotProps="slotProps"
              @click="
                onClickDelete({ id: slotProps.data.id, name: slotProps.data.firstName + ' ' + slotProps.data.lastName })
              "
            ></i>
          </template>
        </Column>
        <template #empty>
          <div class="flex items-center justify-center py-2 md:py-10">
            <NoData
              :title="`Keine Benutzer!`"
              :loading="loading"
              description="Legen Sie bitte oben einen neuen Benutzer an."
            />
          </div>
        </template>
      </DataTable>
    </div>

    <teleport to="body">
      <BaseModal
        :data-modal="dataModalDelete"
        :open="openModal"
        @update:open="openModal = $event"
        @submit="onHandleDelete"
      >
        <template #body="slotProps">
          <div class="flex items-center">
            <i class="pi pi-exclamation-triangle mr-3 text-red text-xl" />
            <span>
              Möchten Sie den Benutzer
              <strong>{{ slotProps.slotProps.name }}</strong>
              wirklich löschen?
            </span>
          </div>
        </template>
      </BaseModal>
    </teleport>
  </div>
</template>
<style>
.p-datatable .p-sortable-column:focus {
  @apply shadow !shadow-xs;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  @apply !text-first;
}
.p-datatable-table .p-datatable-tbody:before {
  content: '-';
  @apply leading-6	text-transparent block;
}
.p-datatable-table .p-datatable-tbody td:last-of-type {
  @apply text-right;
}
.p-datatable .p-datatable-tbody > tr > td {
  @apply px-6 py-2;
}
.p-datatable-table .p-datatable-tbody .p-datatable-emptymessage {
  @apply border-hidden;
}
.p-inputswitch .p-inputswitch-slider {
  @apply !bg-first;
}
.p-inputswitch.disabled .p-inputswitch-slider {
  @apply !bg-light-grey;
}
.p-inputswitch.p-focus .p-inputswitch-slider {
  @apply shadow-none;
}
</style>
