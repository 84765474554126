<script lang="ts" setup>
import { computed, defineProps } from 'vue';
import { useRouter, RouterLink, useRoute } from 'vue-router';

import Menu from 'primevue/menu';
import BaseLogo from '@/components/BaseLogo/BaseLogo.vue';

const router = useRouter();
const route = useRoute();
const routeMetaActive = computed(() => route?.meta?.active);
defineProps<{
  menuItems: {
    label: string;
    to: string;
    icon: string;
  }[];
}>();

const navigate = (pathName: undefined | string) => {
  router.push({ path: pathName });
};
</script>
<template>
  <div class="navigation bg-gray-f3 p-4 flex flex-col justify-between">
    <Menu
      :model="menuItems"
      class="panel menu-sidebar border-none rounded-none shadow-none bg-gray-f3"
    >
      <template #item="{ item }">
        <router-link v-slot="{ isActive, isExactActive }" :to="item.to" custom>
          <a
            :active="isActive"
            class="flex items-center p-3 w-full cursor-pointer rounded-lg hover:bg-primary-light"
            :class="{ 'active-link': isActive || routeMetaActive === item.label, 'active-link-exact': isExactActive || routeMetaActive === item.label }"
            @click="navigate(item.to)"
          ><i class="mr-2" :class="item.icon"/>{{ item.label }}</a>
        </router-link>
      </template>
    </Menu>
    <BaseLogo />
  </div>
</template>
<style scoped>
</style>
<style>
.menu-sidebar {
  width: 14rem;
  height: calc(100vh);
}
.p-menuitem-content > a.active-link {
  @apply font-medium bg-gray-e9 rounded-lg hover:bg-primary-light;
}
.p-menu .p-menuitem:not(.p-highlight):not(.p-disabled).p-focus > .p-menuitem-content {
  @apply bg-gray-f3;
}
</style>