<script lang="ts" setup>
import moment from 'moment';

import PageHeading from '@/components/PageHeading/PageHeading.vue';
import NoData from '@/components/NoData/NoData.vue';
import BaseModal from '@/components/BaseModal/BaseModal.vue';

import { ref, computed } from 'vue';
import { useAppointment } from '@/store/appointment';
import { useUserStore } from '@/store/user';

import { AppointmentStatus } from 'types/appointment-status';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';

import MenuButton from '@/components/MenuButton.vue';

import { FilterMatchMode } from 'primevue/api';
import { IAppointment, IAppointmentOptions, IAppointmentUpdate, IUserOption, IEndpointOption } from 'types/appointment';
import { IDictionary } from 'types/dictionary';

import useMessages from '@/composables/messages';
import { MessageType } from 'types/message';
import { User } from 'types/user';
import { IMenuItems } from 'types/menu-items';
import { Endpoint } from 'types/endpoint';

const { addToast } = useMessages();

const userStore = useUserStore();
const appointmentStore = useAppointment();

await Promise.all([appointmentStore.getAll(), userStore.getAll()]);

const listExpandedAppointment = ref([]);
const listEditingRows = ref<any[]>([]);
const openModal = ref(false);
const dataModal = ref({
  id: '',
  name: '',
  action: '',
});

const listAppointment = computed(() => appointmentStore.listAll);
const appointmentOptions = ref({} as IDictionary<IAppointmentOptions>);

const menuItems = (appointment: IAppointment) => {
  const items: IMenuItems[] = [];

  if (appointment.joinLinkHost) {
    items.push({
      label: 'Termin starten',
      classes: '',
      action: 'start',
    });
  }

  if (appointment.status == AppointmentStatus.PENDING || appointment.status == AppointmentStatus.UNASSIGNED) {
    items.push({
      label: 'Termin ablehnen',
      classes: '',
      action: 'refuse',
    });
  } else {
    items.push({
      label: 'Termin absagen',
      classes: '',
      action: 'cancel',
    });
  }

  return items;
};

// object containing settings for datatable filter
const filters = ref({
  status: { value: null, matchMode: FilterMatchMode.EQUALS },
  'inbound.type': { value: null, matchMode: FilterMatchMode.EQUALS },
  assigned: { value: null, matchMode: FilterMatchMode.EQUALS },
});

// dropdown data for states filter
const statesFilterDropdownData = ref(
  Object.entries(AppointmentStatus).map(([key, value]) => {
    return {
      label: value,
      value: key,
    };
  }),
);

// dropdown data for channels filter
const channelTypesFilterDropdownData = ref([
  { value: 'REMOTE', label: 'Videoberatung' },
  { value: 'PHONE', label: 'Rückruf' },
  { value: 'LOCAL', label: 'Vor-Ort Termin' },
]);

// dropdown data for assigned user filter
const assignedFilterDropdownData = computed(() =>
  userStore.listAllUsers.map((user: User) => ({
    label: `${user.lastName}${user.lastName && user.firstName ? ', ' : ''}${user.firstName}`,
    value: user.id,
  })),
);

const startAppointment = (appointment: IAppointment) => {
  window.open(appointment.joinLinkHost, '_blank').focus();
};

// depending on the appointment status gives badge type
const renderStatusBadge = (status: AppointmentStatus) => {
  switch (status) {
    case AppointmentStatus.ASSIGNED: {
      return 'badge-success';
    }
    case AppointmentStatus.PENDING: {
      return 'badge-info';
    }
    case AppointmentStatus.CANCELLED: {
      return 'badge-dark';
    }
    case AppointmentStatus.REJECTED: {
      return 'badge-info';
    }
    case AppointmentStatus.UNASSIGNED:
    default: {
      return 'badge-danger';
    }
  }
};

const getStatusToolTip = (appointment: IAppointment) => {
  const status: AppointmentStatus = AppointmentStatus[appointment.status];
  if (status === AppointmentStatus.ERROR) return { value: appointment.error, class: 'tooltip-error' };
  return '';
};

const openModalForm = (data: { id: string; name: string; action: string }) => {
  dataModal.value = data;
  openModal.value = true;
};

const onHandleModal = async () => {
  if (!dataModal.value.id) return;

  let isSuccess = false;
  let action = '';
  if (dataModal.value.action === 'cancel') {
    isSuccess = await appointmentStore.cancel(dataModal.value.id);
    action = 'absagen';
  } else if (dataModal.value.action === 'delete') {
    isSuccess = await appointmentStore.delete(dataModal.value.id);
    action = 'löschen';
  } else if (dataModal.value.action === 'refuse') {
    isSuccess = await appointmentStore.refuse(dataModal.value.id);
    action = 'ablehnen';
  }

  if (isSuccess) {
    addToast({
      type: MessageType.Success,
      content: `Termin ${action} erfolgreich`,
    });
  } else {
    addToast({
      type: MessageType.Error,
      content: `Termin ${action} fehlgeschlagen`,
    });
  }
  dataModal.value = { id: '', name: '', action: '' };
  openModal.value = false;
  appointmentStore.getAll();
};

// menu actions for appointment menu
const clickMenu = (menuItem: IMenuItems, appointment: IAppointment) => {
  openModalForm({ id: appointment.id, name: menuItem.label, action: menuItem.action });
  appointmentStore.getAll();
};

// function and list to build details from formData
const excludedFormDataInDetails = ['firstName', 'lastName', 'createdAt', 'updatedAt', 'id', '_id', 'topic'];
const filterFormDataForDetails = (formData: object) => {
  const clone = Object.assign({}, formData);
  excludedFormDataInDetails.forEach((element) => {
    if (element in clone) delete clone[element];
  });
  return clone;
};

// get number of grid rows for current formData
let rowCount = 4;
const gridRowsFromFormData = (formData: object) => {
  const clone = filterFormDataForDetails(formData);

  const propCount = Object.keys(clone).length;
  rowCount = 4;
  if (propCount / 3 > rowCount) rowCount = propCount / 3;

  return `grid-rows-${rowCount}`;
};

// gives grid position of start call button in details view
const startCallButtonDetailsGridPosition = () => {
  return `row-start-${rowCount} row-end-${rowCount + 1}`;
};

// gives grid position of internal notes text field in details view
const internalNotesDetailsGridPosition = () => {
  return `row-start-1 row-span-${rowCount}`;
};

// hold old state on row edit
const onRowEdit = async (event: {
  data: { id: string; agent: { id: string }; endpoint: { id: number }; inbound: { type: string } };
}) => {
  const { data } = event;
  listEditingRows.value = [data];

  const options = (await appointmentStore.getAppointmentOptions(data.id)).data;

  const userOption = options.users.find((user) => user.id === data.agent.id);
  agent.value = userOption ?? { id: null, name: 'Kein Agent ausgewählt', agentId: null };

  const endpointOption = options.endPoints.find((endpoint) => endpoint.id === data.endpoint?.id);
  endPoint.value = endpointOption ?? { id: null, name: 'Kein Endgerät ausgewählt' };

  inboundType.value = data.inbound.type;

  appointmentOptions.value[data.id] = options;
};

// function to handle save of edited row data on server
const onRowEditState = {} as IDictionary<{ agent: { id: string }; endpoint: Endpoint }>;
const onRowEditSave = async (event: {
  newData: { id: string; status: AppointmentStatus; agent: { id: string }; endpoint: Endpoint };
}) => {
  const { newData } = event;
  let changed = false;

  const update: IAppointmentUpdate = {
    agent: agent.value?.id ?? null,
    endpoint: endPoint.value as Endpoint,
  };

  if ((agent.value?.id ?? null) !== (newData.agent?.id ?? null)) {
    changed = true;
  }

  if ((endPoint.value?.id ?? null) !== (newData.endpoint?.id ?? null)) {
    changed = true;
  }

  if (changed || newData.status === 'ERROR') {
    if (isInvalid.value) {
      listEditingRows.value = [newData];
      addToast({
        type: MessageType.Error,
        title: 'Appointment Update Failed',
        content: 'Unable to create meeting with selected agent and endpoint',
      });
      return;
    }

    await updateAppointmentData(newData.id, update);
  }

  delete onRowEditState[newData.id];
  delete appointmentOptions.value[newData.id];
};

// function to handle save of edited details data on server
const onRowDetailsCollapse = (event: { data: { id: string; notes: string } }) => {
  const { data } = event;

  const beforeEditState = onRowExpandRowState[data.id];

  if (beforeEditState.notes !== data.notes) {
    const update: IAppointmentUpdate = { notes: data.notes };
    updateAppointmentData(data.id, update);
  }

  delete onRowExpandRowState[data.id];
};

// function to store unedited data to check if data has been edited
const onRowExpandRowState = {} as IDictionary<IAppointmentUpdate>;
const onRowDetailsExpand = (event: { data: { id: string; notes: string } }) => {
  const { data } = event;
  onRowExpandRowState[data.id] = { notes: data.notes };
};

// function to send appointment changes to server
const updateAppointmentData = async (id: string, update: IAppointmentUpdate) => {
  const isSuccess: boolean = await appointmentStore.update(id, update);
  if (isSuccess) {
    addToast({
      type: MessageType.Success,
      content: 'Appointment Update Success',
    });
  } else {
    addToast({
      type: MessageType.Error,
      content: 'Appointment Update Failed',
    });
  }
  appointmentStore.getAll();
};

// function to convert unix number to date string
const unixToDate = (unix: number) => {
  const date = new Date(unix * 1000);
  const m = moment(date);
  return m.format('DD.MM.yyyy');
};

// function to convert unix number to date string
const unixToTime = (unix: number) => {
  const date = new Date(unix * 1000);
  const m = moment(date);
  return m.format('HH:mm');
};

const loading = ref(false);
const refreshListData = async () => {
  loading.value = true;
  await appointmentStore.getAll();
  listEditingRows.value = [];
  loading.value = false;
};

const inboundType = ref<string>();
const agent = ref<IUserOption>();
const endPoint = ref<IEndpointOption>();

const isInvalid = computed(() => {
  if (inboundType.value === 'REMOTE') {
    return !agent.value?.agentId && !endPoint.value?.id;
  }

  return !agent.value;
});
</script>
<template>
  <div class="container-fluid">
    <PageHeading
      title="Termine"
      class="mt-2"
      to="/"
      :button="false"
      :refresh="true"
      :loading="loading"
      @click-refresh="refreshListData"
    />

    <DataTable
      v-model:editingRows="listEditingRows"
      v-model:expandedRows="listExpandedAppointment"
      v-model:filters="filters"
      sort-field="slotStart"
      :sort-order="-1"
      :value="listAppointment"
      filter-display="menu"
      class="appointment-table"
      expandable-rows="true"
      responsive-layout="scroll"
      edit-mode="row"
      :loading="loading"
      @row-edit-init="onRowEdit"
      @row-edit-save="onRowEditSave"
      @row-collapse="onRowDetailsCollapse"
      @row-expand="onRowDetailsExpand"
    >
      <Column field="date" header="Datum/Uhrzeit" :sortable="true" sort-field="slotStart">
        <template #body="slotProps">
          {{ unixToDate(slotProps.data.slotStart) }} / {{ unixToTime(slotProps.data.slotStart) }} -
          {{ unixToTime(slotProps.data.slotEnd) }}
        </template>
      </Column>

      <Column
        field="channel"
        header="Kalender"
        filter-field="inbound.type"
        :sortable="true"
        :show-filter-match-modes="false"
        sort-field="inbound.name"
      >
        <template #body="slotProps">{{ slotProps.data.inbound.name }}</template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            :options="channelTypesFilterDropdownData"
            placeholder="Alle"
            class="p-column-filter"
            option-label="label"
            option-value="value"
            :show-clear="true"
            @change="filterCallback"
          />
        </template>
        <template #filterapply="{}"></template>
        <template #filterclear="{}"></template>
      </Column>

      <Column
        field="status"
        header="Status"
        :sortable="true"
        :show-filter-match-modes="false"
        :show-filter-operator="false"
        sort-field="status"
      >
        <template #body="slotProps">
          <div
            v-tooltip="getStatusToolTip(slotProps.data)"
            class="badge flex items-center justify-center"
            :class="renderStatusBadge(AppointmentStatus[slotProps.data.status])"
          >
            <span>{{ AppointmentStatus[slotProps.data.status] }}</span>
          </div>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            :options="statesFilterDropdownData"
            placeholder="Alle"
            class="p-column-filter"
            option-label="label"
            option-value="value"
            :show-clear="true"
            @change="filterCallback"
          />
        </template>
        <template #filterapply="{}"></template>
        <template #filterclear="{}"></template>
      </Column>

      <Column field="customer" header="Kunde" :sortable="true" sort-field="formData.firstName">
        <template #body="slotProps">
          {{
            `${slotProps.data.formData.lastName}${
              slotProps.data.formData.lastName && slotProps.data.formData.firstName ? ', ' : ''
            }${slotProps.data.formData.firstName}`
          }}
        </template>
      </Column>

      <Column field="topic" header="Thema" :sortable="true" sort-field="formData.topic">
        <template #body="slotProps">{{ slotProps.data.formData.topic }}</template>
      </Column>

      <Column
        field="assigned"
        header="Verantwortlich"
        :show-filter-match-modes="false"
        :sortable="true"
        sort-field="agent.firstName"
      >
        <template #body="slotProps">
          <div>
            {{ slotProps.data.agent.lastName }}
            {{ slotProps.data.agent.lastName && slotProps.data.agent.firstName ? ', ' : '' }}
            {{ slotProps.data.agent.firstName }}
          </div>
        </template>
        <template #editor="{ data }">
          <!--
              WORKAROUND
              Check if Appointment is PENDING / Has no available Endpoints / Has only the already selected endpoint
              If this is the case there is no option to edit it.
          -->
          <div v-if="data.id in appointmentOptions">
            <div
              v-if="
                data.status === 'PENDING' ||
                appointmentOptions[data.id].users.length == 0 ||
                (appointmentOptions[data.id].users.length == 1 && appointmentOptions[data.id].users[0] === data.agent)
              "
            >
              <div v-if="data.agent">
                {{ data.agent.lastName }}
                {{ data.agent.lastName && data.agent.firstName ? ', ' : '' }}
                {{ data.agent.firstName }}
              </div>
            </div>
            <div v-else>
              <Dropdown
                id="assigned"
                v-model="agent"
                :filter="true"
                :options="[{ id: null, name: 'Kein Agent ausgewählt' }].concat(appointmentOptions[data.id].users)"
                option-label="name"
                option-disabled="disabled"
                class="w-full"
                :empty-message="'Keine Agenten verfügbar'"
              ></Dropdown>
            </div>
          </div>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <Dropdown
            v-model="filterModel.value"
            :options="assignedFilterDropdownData"
            placeholder="Alle"
            class="p-column-filter"
            option-label="label"
            option-value="value"
            :show-clear="true"
            @change="filterCallback"
          />
        </template>
        <template #filterapply="{}"></template>
        <template #filterclear="{}"></template>
      </Column>

      <Column field="endpoint" header="Ort/Endgerät" :sortable="true" sort-field="endpoint.name">
        <template #body="slotProps">
          <div v-if="slotProps.data.inbound.type === 'REMOTE'">{{ slotProps.data.endpoint?.name }}</div>
          <div v-else-if="slotProps.data.inbound.type === 'LOCAL'">{{ slotProps.data.inbound.localBranch.name }}</div>
          <div v-else></div>
        </template>
        <template #editor="{ data }">
          <template v-if="data.inbound.type === 'REMOTE'">
            <!--
              WORKAROUND
              Check if Appointment is PENDING / Has no available Endpoints / Has only the already selected endpoint
              If this is the case there is no option to edit it.
            -->
            <div v-if="data.id in appointmentOptions">
              <div v-if="data.status === 'PENDING' || appointmentOptions[data.id].endPoints.length === 0">
                <div v-if="data.inbound.type === 'REMOTE'">{{ data.endpoint?.name }}</div>
                <div v-else>{{ data.inbound.localBranch.name }}</div>
              </div>
              <div v-else-if="isInvalid">
                <Dropdown
                  v-if="agent?.id"
                  id="endpoint"
                  v-model="endPoint"
                  option-label="name"
                  option-disabled="disabled"
                  class="w-full text-left p-invalid"
                  :filter="true"
                  :options="
                    [{ id: null, name: 'Kein Endgerät ausgewählt' }].concat(appointmentOptions[data.id].endPoints)
                  "
                  :empty-message="'Keine Endpunkte verfügbar'"
                ></Dropdown>
              </div>
              <div v-else>
                <Dropdown
                  v-if="agent?.id"
                  id="endpoint"
                  v-model="endPoint"
                  option-label="name"
                  option-disabled="disabled"
                  class="w-full text-left"
                  :filter="true"
                  :options="
                    [{ id: null, name: 'Kein Endgerät ausgewählt' }].concat(appointmentOptions[data.id].endPoints)
                  "
                  :empty-message="'Keine Endpunkte verfügbar'"
                ></Dropdown>
              </div>
            </div>
          </template>
          <template v-else>{{ data.inbound.localBranch.name }}</template>
        </template>
      </Column>

      <Column :expander="true" />

      <!--
        TODO
        This button should be disabled / invisible if there are no available options / appotiontment is PENDING.
        Connected to the above WORKAROUND comments.
      -->
      <Column :row-editor="true" body-style="text-align:center"></Column>

      <Column field="menu">
        <template #body="slotProps">
          <MenuButton :appointment="slotProps.data" :menu-items="menuItems(slotProps.data)" @click-menu="clickMenu" />
        </template>
      </Column>
      <template #expansion="slotProps">
        <div
          class="expand-content grid gap-y-4 gap-x-4 grid-cols-4 text-left text-xs pt-8 pb-20"
          :class="gridRowsFromFormData(slotProps.data.formData)"
        >
          <div>
            <div class="text-xs text-dark-grey font-semibold">Erstellung</div>
            <div class="text-s text-dark-grey mt-2">
              {{ moment(slotProps.data.createdAt).format('DD.MM.YYYY / HH:mm') }}
            </div>
          </div>

          <div>
            <div class="text-xs text-dark-grey font-semibold">Letzte Änderung</div>
            <div class="text-s text-dark-grey mt-2">
              {{ moment(slotProps.data.updatedAt).format('DD.MM.YYYY / HH:mm') }}
            </div>
          </div>

          <div v-if="slotProps.data.pinHost">
            <div class="text-xs text-dark-grey font-semibold">Host PIN</div>
            <div class="text-s text-dark-grey mt-2">{{ slotProps.data.pinHost }}</div>
          </div>

          <div v-for="(data, propertyName, index) in filterFormDataForDetails(slotProps.data.formData)" :key="index">
            <div class="text-xs text-dark-grey font-semibold">
              {{
                slotProps.data.formConfig
                  ? slotProps.data.formConfig[propertyName]?.label
                  : slotProps.data.inbound.formConfig[propertyName]?.label
              }}
            </div>
            <div class="text-s text-dark-grey mt-2">{{ data }}</div>
          </div>

          <div class="col-start-4 col-end-5" :class="internalNotesDetailsGridPosition()">
            <div class="text-xs text-dark-grey font-semibold mb-2">Interne Notiz</div>
            <div>
              <Textarea
                v-model="slotProps.data.notes"
                class="w-full"
                rows="8"
                :disabled="slotProps.data.status === 'PENDING'"
                :auto-resize="true"
              />
            </div>
          </div>

          <div class="col-start-4 col-end-5" :class="startCallButtonDetailsGridPosition()">
            <div class="flex justify-end">
              <Button
                label="Termin starten"
                class="p-button-outlined p-button-secondary text-s text-black !border-light-grey !font-normal"
                @click="startAppointment(slotProps.data)"
              />
            </div>
          </div>
        </div>
      </template>
    </DataTable>

    <div v-if="listAppointment?.length === 0">
      <div class="flex items-center justify-center mt-20">
        <NoData
          title="Keine Termine!"
          :loading="loading"
          description="Abonnieren Sie Kalender in den <strong>Profileinstellungen</strong>."
        />
      </div>
    </div>
    <teleport to="body">
      <BaseModal :data-modal="dataModal" :open="openModal" @update:open="openModal = $event" @submit="onHandleModal">
        <template #body="{ slotProps }">
          <div class="flex items-center">
            <i class="pi pi-exclamation-triangle mr-3 text-red text-xl" />
            <span>Are you sure you want to {{ slotProps.action }} the appointment?</span>
          </div>
        </template>
      </BaseModal>
    </teleport>
  </div>
</template>
<style>
.appointment-table .p-dropdown .p-dropdown-label {
  @apply py-2 pl-3 pr-1 text-s;
}
.ml-22 {
  margin-left: 22rem;
}
.appointment-table .expand-content {
  margin-left: 5.5rem;
}
.appointment-table .p-row-toggler .p-row-toggler-icon {
  transform: rotate(180deg);
}
.appointment-table .badge {
  border-radius: 99px;
}
.appointment-table .badge span {
  @apply font-bold px-3 py-1 text-xs;
  border-radius: 99px;
}

.appointment-table .badge-danger {
  background: rgba(231, 37, 83, 0.15);
}
.appointment-table .badge-danger span {
  color: #dd0035;
}
.appointment-table .badge-success {
  background: rgba(21, 203, 72, 0.15);
}
.appointment-table .badge-success span {
  color: #00a743;
}
.appointment-table .badge-info {
  background: #d1d1d1;
}
.appointment-table .badge-dark {
  background: #afafaf;
}
.appointment-table .badge-info span {
  @apply text-white;
}
.appointment-table .p-cell-editing .p-row-editor-cancel {
  @apply hidden;
}

.row-auto {
  grid-row: auto;
}
.row-span-1 {
  grid-row: span 1 / span 1;
}
.row-span-2 {
  grid-row: span 2 / span 2;
}
.row-span-3 {
  grid-row: span 3 / span 3;
}
.row-span-4 {
  grid-row: span 4 / span 4;
}
.row-span-5 {
  grid-row: span 5 / span 5;
}
.row-span-6 {
  grid-row: span 6 / span 6;
}
.row-span-7 {
  grid-row: span 7 / span 7;
}
.row-span-8 {
  grid-row: span 8 / span 8;
}
.row-span-9 {
  grid-row: span 9 / span 9;
}
.row-span-10 {
  grid-row: span 10 / span 10;
}

.tooltip-error .p-tooltip-text {
  background-color: var(--pink-800);
  color: rgb(255, 255, 255);
}
.tooltip-error.p-tooltip-right .p-tooltip-arrow {
  border-right-color: var(--pink-800);
}
</style>
