import { defineStore } from 'pinia';
import UserApi from '@/services/UserApi';
import { User, UserAgent } from 'types/user';

const userApi = new UserApi();
export const useUserStore = defineStore('user', {
  state: () => {
    return {
      listAllUsers: <User[]>[],
      listAllAgents: <UserAgent[]>[],
    };
  },
  actions: {
    async getAll() {
      try {
        const { data } = await userApi.getAll();
        this.listAllUsers = data;
        return data;
      } catch (e) {
        return e;
      }
    },
    async getUserAgents(): Promise<UserAgent[]> {
      try {
        const { data } = await userApi.getAgents();
        this.listAllAgents = data.agents;
        return data.agents;
      } catch (e) {
        return e;
      }
    },
    async createUser(body: object = {}) {
      try {
        await userApi.create(body);
        return true;
      } catch (e) {
        return false;
      }
    },
    async getUserDetail(id: string): Promise<User> {
      try {
        const { data } = await userApi.detail(id);
        return data;
      } catch (e) {
        return e;
      }
    },
    async updateUser(id: string, body: object = {}) {
      try {
        await userApi.update(id, body);
        return true;
      } catch (e) {
        return false;
      }
    },
    async deleteUser(id: string) {
      try {
        await userApi.remove(id);
        return true;
      } catch (e) {
        return false;
      }
    },
  },
});
