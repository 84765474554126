<script lang="ts" setup>
import { computed } from 'vue';
import Cleave from 'vue-cleave-component';

const props = defineProps({
  placeholder: {
    type: String,
    required: false,
    default: 'dd.mm.jjjj',
  },
  value: {
    type: String,
    required: false,
    default: '',
  },
  delimiter: {
    type: String,
    required: false,
    default: '.',
  },
  maskPattern: {
    type: Array,
    required: false,
    default: () => ['d', 'm', 'y'],
  },
  invalid: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(['input']);

const inputVal = computed(() => props.value);

const options = {
  date: true,
  delimiter: props.delimiter,
  datePattern: props.maskPattern,
  onValueChanged: function (e: { target: { name: string; rawValue: ''; value: string } }) {
    emit('input', e.target.value);
  },
};
</script>

<template>
  <cleave
    v-model="inputVal"
    :class="{'p-invalid': invalid}"
    :options="options"
    class="p-inputtext p-component w-full md:w-20 h-9 text-s placeholder:text-light-grey p-2 rounded text-dark-grey"
    :placeholder="placeholder"
    name="card"
  />
</template>
