type Message = {
  title?: string;
  content: string;
  type: MessageType;
  displayTime?: number; // in ms
};
enum MessageType {
  Success = 'success',
  Info = 'info',
  Warn = 'warn',
  Error = 'error',
}

export { Message, MessageType };
