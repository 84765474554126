import BaseApi from './BaseApi';
import { BASE_URL } from 'types/config';
import { ILocalBranch } from 'types/local-branch';
import { AxiosPromise } from 'axios';

export default class LocalBranchApi extends BaseApi {
  private baseURL = `${BASE_URL}/local-branch`;
  constructor() {
    super();
  }

  public getAll(): AxiosPromise<ILocalBranch[]> {
    return this.get<ILocalBranch[]>(this.baseURL);
  }

  public create(body: ILocalBranch): AxiosPromise<ILocalBranch> {
    return this.post<ILocalBranch>(this.baseURL, body);
  }

  public detail(id: string): AxiosPromise<ILocalBranch> {
    return this.get<ILocalBranch>(`${this.baseURL}/${id}`);
  }

  public remove(id: string): AxiosPromise<void> {
    return this.delete<void>(`${this.baseURL}/${id}`);
  }

  public update(id: string, body: ILocalBranch): AxiosPromise<ILocalBranch> {
    return this.patch<ILocalBranch>(`${this.baseURL}/${id}`, body);
  }
}
