import { defineStore } from 'pinia';
import AppointmentApi from '@/services/AppointmentApi';
import { IAppointment, IAppointmentUpdate } from 'types/appointment';

const appointmentApi = new AppointmentApi();
export const useAppointment = defineStore('appointment', {
  state: () => {
    return {
      listAll: <IAppointment[]>[],
    };
  },
  actions: {
    async getAll() {
      try {
        const { data } = await appointmentApi.getAll();
        data.map((d) => {
          d['agent'] ||= {};
        });

        this.listAll = data;
        return data;
      } catch (e) {
        return e;
      }
    },
    async getAppointmentOptions(id: string) {
      try {
        const options = await appointmentApi.options(id);
        return options;
      } catch (e) {
        return { data: { users: [], endPoints: [] } };
      }
    },
    async create(body: IAppointment) {
      try {
        await appointmentApi.add(body);
        return true;
      } catch (e) {
        return false;
      }
    },
    async getDetail(id: string) {
      try {
        const { data } = await appointmentApi.detail(id);
        return data;
      } catch (e) {
        return e;
      }
    },
    async update(id: string, body: IAppointmentUpdate) {
      try {
        await appointmentApi.update(id, body);
        return true;
      } catch (e) {
        return false;
      }
    },
    async cancel(id: string) {
      try {
        await appointmentApi.cancel(id);
        return true;
      } catch (e) {
        return false;
      }
    },
    async refuse(id: string) {
      try {
        await appointmentApi.refuse(id);
        return true;
      } catch (e) {
        return false;
      }
    },
    async delete(id: string) {
      try {
        await appointmentApi.remove(id);
        return true;
      } catch (e) {
        return false;
      }
    },
  },
});

export default useAppointment;
