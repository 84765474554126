<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useLocalBranch } from '@/store/local-branch';

import PageHeading from '@/components/PageHeading/PageHeading.vue';
import BaseModal from '@/components/BaseModal/BaseModal.vue';
import NoData from '@/components/NoData/NoData.vue';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

const localBranchStore = useLocalBranch();

await localBranchStore.getAll();

const route = useRoute();
const router = useRouter();
const pageName = route.name;

const openModal = ref(false);
const dataModalDelete = ref({
  id: '',
  name: '',
});

const onClickDelete = (data: { id: string; name: string }) => {
  dataModalDelete.value = data;
  openModal.value = true;
};

const onHandleDelete = async () => {
  if (!dataModalDelete.value.id) return;
  await localBranchStore.delete(dataModalDelete.value.id);
  dataModalDelete.value = { id: '', name: '' };
  openModal.value = false;
  localBranchStore.getAll();
};

const listLocalBranch = computed(() => localBranchStore.listAll);

const loading = ref(false);
const refreshListData = async () => {
  loading.value = true;
  await localBranchStore.getAll();
  loading.value = false;
};
</script>
<template>
  <div class="page-content w-full">
    <PageHeading
      class="mb-5"
      :title="String(pageName)"
      :button="true"
      to="/admin/branch/add"
      :refresh="true"
      :loading="loading"
      @click-refresh="refreshListData"
    />
    <div class="card">
      <DataTable :value="listLocalBranch" responsive-layout="scroll" class="custom-table" :loading="loading">
        <Column field="name" header="Name" :sortable="true" sort-field="name">
          <template #body="slotProps">
            <span>{{ slotProps.data.name }}</span>
          </template>
        </Column>
        <Column field="street" header="Ort" :sortable="true" sort-field="street">
          <template #body="slotProps">
            <span>{{ slotProps.data.street }}</span>
          </template>
        </Column>
        <Column field="active" header>
          <template #body="slotProps">
            <i
              class="pi pi-cog text-l cursor-pointer hover:text-first p-2 mr-2"
              :slotProps="slotProps"
              @click="router.push({ path: `/admin/branch/${slotProps.data.id}/edit` })"
            ></i>
            <i
              class="pi pi-trash text-l cursor-pointer text-red hover:text-red p-2"
              :slotProps="slotProps"
              @click="onClickDelete({ id: slotProps.data.id, name: slotProps.data.name })"
            ></i>
          </template>
        </Column>
        <template #empty>
          <div class="flex items-center justify-center py-2 md:py-10">
            <NoData
              :title="`Keine Filialen!`"
              :loading="loading"
              description="Legen Sie bitte oben eine neue Filiale an."
            />
          </div>
        </template>
      </DataTable>
    </div>
  </div>
  <teleport to="body">
    <BaseModal
      :data-modal="dataModalDelete"
      :open="openModal"
      @update:open="openModal = $event"
      @submit="onHandleDelete"
    >
      <template #body="{ slotProps }">
        <div class="flex items-center">
          <i class="pi pi-exclamation-triangle mr-3 text-red text-xl" />
          <span>
            Möchten Sie die Filiale
            <strong>{{ slotProps.name }}</strong>
            wirklich löschen?
          </span>
        </div>
      </template>
    </BaseModal>
  </teleport>
</template>
<style>
.p-datatable .p-sortable-column:focus {
  @apply shadow !shadow-xs;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  @apply !text-first;
}
.p-datatable-table .p-datatable-tbody:before {
  content: '-';
  @apply leading-6	text-transparent block;
}
.custom-table.p-datatable-table .p-datatable-tbody td:last-of-type {
  @apply text-right;
}
.p-datatable .p-datatable-tbody > tr > td {
  @apply px-6 py-2;
}
.p-datatable-table .p-datatable-tbody .p-datatable-emptymessage {
  @apply border-hidden;
}
.p-inputswitch .p-inputswitch-slider {
  @apply !bg-first;
}
.p-inputswitch.disabled .p-inputswitch-slider {
  @apply !bg-light-grey;
}
.p-inputswitch.p-focus .p-inputswitch-slider {
  @apply shadow-none;
}
</style>
