import { defineStore } from 'pinia';
import { ISnippet } from 'types/snippet';
import SnippetApi from '@/services/SnippetApi';

const snippetApi = new SnippetApi();
export const useSnippet = defineStore('snippet', {
  state: () => {
    return {
      listAll: <ISnippet[]>[],
      defaults: <Array<object>>[],
    };
  },
  actions: {
    async getAll() {
      try {
        const { data } = await snippetApi.getAll();
        this.listAll = data;
        return data;
      } catch (e) {
        return e;
      }
    },
    async getDefaults() {
      try {
        const { data } = await snippetApi.getDefaults();
        this.defaults = data;
        return data;
      } catch (e) {
        return e;
      }
    },
    async create(body: { snippetId: string; value: string; tenantId: string }) {
      try {
        await snippetApi.create(body);
        return true;
      } catch (e) {
        return false;
      }
    },
    async getDetail(id: string) {
      try {
        const { data } = await snippetApi.detail(id);
        return data;
      } catch (e) {
        return e;
      }
    },
    async update(id: string, body: { snippetId: string; value: string; tenantId: string }) {
      try {
        await snippetApi.update(id, body);
        return true;
      } catch (e) {
        return false;
      }
    },
    async delete(id: string) {
      try {
        await snippetApi.remove(id);
      } catch (e) {
        return e;
      }
    },
  },
});
