<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useTenantStore } from '@/store/tenant';

import PageHeading from '@/components/PageHeading/PageHeading.vue';
import BaseModal from '@/components/BaseModal/BaseModal.vue';
import NoData from '@/components/NoData/NoData.vue';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

import useMessages from '@/composables/messages';
import { MessageType } from 'types/message';
const { addToast } = useMessages();

const tenantStore = useTenantStore();
await tenantStore.getAll();

const route = useRoute();
const router = useRouter();
const pageName = route.name;

const openModal = ref(false);
const dataModalDelete = ref<{ id: string; name: string }>({
  id: '',
  name: '',
});

const onClickDelete = (data: { id: string; name: string }) => {
  dataModalDelete.value = data;
  openModal.value = true;
};

const onHandleDelete = async () => {
  if (!dataModalDelete.value.id) return;
  const isSuccess: boolean = await tenantStore.deleteTenant(dataModalDelete.value.id);
  dataModalDelete.value = { id: '', name: '' };
  openModal.value = false;
  tenantStore.getAll();

  if (isSuccess) {
    addToast({
      type: MessageType.Success,
      content: `Benutzer löschen erfolgreich`,
    });
  } else {
    addToast({
      type: MessageType.Error,
      content: `Benutzer löschen fehlgeschlagen`,
    });
  }
};

const listTenants = computed(() => tenantStore.listAllTenants);

const loading = ref(false);
const refreshListData = async () => {
  loading.value = true;
  await tenantStore.getAll();
  loading.value = false;
};
</script>
<template>
  <div class="page-content w-full">
    <PageHeading
      class="mb-5"
      :title="pageName"
      :button="true"
      to="/admin/tenant/add"
      :refresh="true"
      :loading="loading"
      @click-refresh="refreshListData"
    />
    <div class="card">
      <DataTable :value="listTenants" responsive-layout="scroll" :loading="loading">
        <Column field="name" header="Name" :sortable="true" sort-field="name">
          <template #body="slotProps">
            <span>{{ slotProps.data.name }}</span>
          </template>
        </Column>
        <Column field="customerId" header="VQ-CustomerID" :sortable="true" sort-field="customerId">
          <template #body="slotProps">
            <span>{{ slotProps.data.customerId }}</span>
          </template>
        </Column>
        <Column field="spaceTemplateId" header="VQ-SpacetemplateID" :sortable="true" sort-field="spaceTemplateId">
          <template #body="slotProps">
            <span>{{ slotProps.data.spaceTemplateId }}</span>
          </template>
        </Column>
        <Column
          field="joinlinkTemplateId"
          header="VQ-JoinlinkTemplateID"
          :sortable="true"
          sort-field="joinlinkTemplateId"
        >
          <template #body="slotProps">
            <span>{{ slotProps.data.joinlinkTemplateId }}</span>
          </template>
        </Column>

        <Column field="active" header>
          <template #body="slotProps">
            <i
              class="pi pi-cog text-l cursor-pointer hover:text-first p-2 mr-2"
              :slotProps="slotProps"
              @click="router.push({ name: 'Tenant bearbeiten', params: { id: slotProps.data.id } })"
            ></i>
            <i
              class="pi pi-trash text-l cursor-pointer text-red hover:text-red p-2"
              :slotProps="slotProps"
              @click="onClickDelete({ id: slotProps.data.id, name: slotProps.data.name })"
            ></i>
          </template>
        </Column>
        <template #empty>
          <div class="flex items-center justify-center py-2 md:py-10">
            <NoData
              :title="`Keine Tenants!`"
              :loading="loading"
              description="Legen Sie bitte oben einen neuen Tenant an."
            />
          </div>
        </template>
      </DataTable>
    </div>

    <teleport to="body">
      <BaseModal
        :data-modal="dataModalDelete"
        :open="openModal"
        @update:open="openModal = $event"
        @submit="onHandleDelete"
      >
        <template #body="slotProps">
          <div class="flex items-center">
            <i class="pi pi-exclamation-triangle mr-3 text-red text-xl" />
            <span>
              Möchten Sie den Tenant
              <strong>{{ slotProps.slotProps.name }}</strong>
              wirklich löschen?
            </span>
          </div>
        </template>
      </BaseModal>
    </teleport>
  </div>
</template>
<style>
.p-datatable .p-sortable-column:focus {
  @apply shadow !shadow-xs;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
  @apply !text-first;
}
.p-datatable-table .p-datatable-tbody:before {
  content: '-';
  @apply leading-6	text-transparent block;
}
.p-datatable-table .p-datatable-tbody td:last-of-type {
  @apply text-right;
}
.p-datatable .p-datatable-tbody > tr > td {
  @apply px-6 py-2;
}
.p-datatable-table .p-datatable-tbody .p-datatable-emptymessage {
  @apply border-hidden;
}
.p-inputswitch .p-inputswitch-slider {
  @apply !bg-first;
}
.p-inputswitch.disabled .p-inputswitch-slider {
  @apply !bg-light-grey;
}
.p-inputswitch.p-focus .p-inputswitch-slider {
  @apply shadow-none;
}
</style>
