import { defineStore } from 'pinia';
import TenantApi from '@/services/TenantApi';
import { ITenant, ITenantDeviceOption, ITenantOptions } from 'types/tenant';

const tenantApi = new TenantApi();
export const useTenantStore = defineStore('tenant', {
  state: () => {
    return {
      listAllTenants: <ITenant[]>[],
      options: <ITenantOptions>{
        customers: [],
        spaceTemplates: [],
        joinlinkTemplates: [],
      },
    };
  },
  actions: {
    async getAll() {
      try {
        const { data } = await tenantApi.getAll();
        this.listAllTenants = data;
        return data;
      } catch (e) {
        return e;
      }
    },
    async getTenantOptions(): Promise<ITenantOptions> {
      try {
        const { data } = await tenantApi.getOptions();
        this.options = data;
        return data;
      } catch (e) {
        return e;
      }
    },
    async createTenant(body: object = {}) {
      try {
        await tenantApi.add(body);
        return true;
      } catch (e) {
        return false;
      }
    },
    async getTenantDetail(id: string): Promise<ITenant> {
      try {
        const { data } = await tenantApi.detail(id);
        return data;
      } catch (e) {
        return e;
      }
    },
    async getTenantDevices(id: string): Promise<ITenantDeviceOption[]> {
      try {
        const { data } = await tenantApi.devices(id);
        return data;
      } catch (e) {
        return e;
      }
    },
    async updateTenant(id: string, body: object = {}) {
      try {
        await tenantApi.update(id, body);
        return true;
      } catch (e) {
        return false;
      }
    },
    async deleteTenant(id: string) {
      try {
        await tenantApi.remove(id);
        return true;
      } catch (e) {
        return false;
      }
    },
    async checkMailSettings(body: object = {}) {
      await tenantApi.checkMailSettings(body);
    },
  },
});
