<script lang="ts" setup>
// Prime Components
import Sidebar from '@/components/layouts/Sidebar.vue';

const menuItems = [
  {
    label: 'Mein Profil',
    to: 'MyProfile',
  },
  // {
  //   label: 'E-Mail Einstellungen',
  //   to: 'EmailSetting',
  // },
  // {
  //   label: 'Account Einstellungen',
  //   to: 'AccountSetting',
  // },
];
</script>
<template>
  <div class="container-fluid flex">
    <Sidebar :menu-items="menuItems" />
    <router-view :key="$route.path"></router-view>
  </div>
</template>
