import { defineStore } from 'pinia';
import { RemovableRef, useStorage } from '@vueuse/core';

import axios from '@/plugins/axios';
import { AxiosPromise } from 'axios';
import { CallbackPayload } from 'types/auth';
import { User } from 'types/user';
import router from '~/router/index';

const apiUrl = import.meta.env.VITE_BACKEND_URL;

const userStorage: RemovableRef<string | null> = useStorage('user', null);
const accessTokenStorage: RemovableRef<string | null> = useStorage('accessToken', null);
const refreshTokenStorage: RemovableRef<string | null> = useStorage('refreshToken', null);

export const useAuth = defineStore('auth', {
  state: () => {
    return {
      user: <User | null>userStorage.value ? JSON.parse(userStorage.value) : null,
      token: <null | string>accessTokenStorage.value,
      refreshToken: <null | string>refreshTokenStorage.value,
      isRefreshing: <AxiosPromise | undefined>undefined,
    };
  },
  actions: {
    /**
     *
     * @returns {string} token
     * return token from state or cookie
     */
    async getToken(): Promise<string | null> {
      if (this.token) {
        return this.token;
      }

      this.token = accessTokenStorage.value;
      if (this.token) {
        return this.token;
      }
      return null;
    },
    /**
     *
     * @returns {string} refreshToken
     * return refresh token from state or cookie
     */
    async getRefreshToken(): Promise<string | null> {
      if (this.refreshToken) {
        return this.refreshToken;
      }

      this.refreshToken = refreshTokenStorage.value;
      if (this.refreshToken) {
        return this.refreshToken;
      }
      return null;
    },
    /**
     *
     * @returns {boolean | AxiosPromise} flase or Promise if requesting a new token
     * referesh the token and update cookie
     */
    async refresh() {
      // check if already refreshing
      if (this.isRefreshing) return false;

      if (!this.token) {
        this.token = accessTokenStorage.value;
      }
      if (!this.refreshToken) {
        this.refreshToken = refreshTokenStorage.value;
      }
      if (this.token) {
        // saving the promise in a variable
        this.isRefreshing = axios.post(apiUrl + '/auth/refresh', {
          refreshToken: this.refreshToken,
        });
        this.isRefreshing
          .then((response) => {
            this.isRefreshing = undefined;
            this.setDataLogin(response.data);
          })
          .catch((error) => {
            this.isRefreshing = undefined;
            this.logout();
            router.push({ name: 'login' });
            return Promise.reject(error);
          });
        return this.isRefreshing;
      }
      return false;
    },
    async requestToken(payload: CallbackPayload) {
      try {
        const { data } = await axios.get(apiUrl + '/auth/callback', {
          params: payload,
        });
        this.setDataLogin(data);
        return true;
      } catch (error) {
        console.log('refreshing', error);
        return false;
      }
    },
    async logout() {
      this.token = null;
      this.refreshToken = null;
      accessTokenStorage.value = null;
      refreshTokenStorage.value = null;
      userStorage.value = null;
    },
    async getUser() {
      if (!this.user) {
        if (userStorage.value) this.user = JSON.parse(userStorage.value);
      }
      return this.user;
    },

    /**
     * @params  data login
     *
     * set token is fetch to local storage
     */
    setDataLogin(data: { accessToken: string; refreshToken: string; user: User }) {
      this.token = data.accessToken;
      this.refreshToken = data.refreshToken;
      this.user = data.user;
      accessTokenStorage.value = data.accessToken;
      refreshTokenStorage.value = data.refreshToken;
      userStorage.value = JSON.stringify(data.user);
    },
  },
  getters: {
    isAuthenticated: (state: { token: string | null }) => {
      return !!state.token;
    },
  },
});
