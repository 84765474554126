<template>
  <template v-if="info">
    <transition name="fade" mode="out-in">
      <keep-alive>
        <suspense>
          <MainHeader :img="img" />
          <template #fallback>
            <div class="grid place-content-center">
              <ProgressSpinner />
            </div>
          </template>
        </suspense>
      </keep-alive>
    </transition>

    <div class="flex items-center justify-center callback">
      <div v-if="info?.success">
        <div class="flex items-center justify-center">
          <strong>{{ snippets.header_success }}</strong>
        </div>
        <div v-if="info.start">
          <span>{{ snippets.start_countdown.replace(timeRemainingTemplate, timeRemaining) }} </span>
        </div>
        <div v-if="info.message" v-html="info.message"></div>
        <Button
          v-if="info.joinUrl"
          :label="snippets.join_button"
          class="p-button-primary bg-first hover:!bg-first border-none text-s"
          @click="joinMeeting"
        />
      </div>
      <div v-else>
        <div class="flex items-center justify-center">
          <strong>{{ snippets.header_error }}</strong>
        </div>
        <div v-if="info.message" v-html="info.message"></div>
      </div>
    </div>
  </template>
</template>
<script setup lang="ts">
import { BASE_URL } from 'types/config';
import { useRoute } from 'vue-router';

import MainHeader from '@/components/layouts/MainHeader.vue';
import ProgressSpinner from 'primevue/progressspinner';
import AppointmentApi from '@/services/AppointmentApi';
import { computed, ref, watch } from 'vue';
import { IAppointmentJoinInfo } from 'types/appointment';
import { useTimeoutFn, useTimestamp } from '@vueuse/core';
import Button from 'primevue/button';

const timestamp = useTimestamp({ offset: 0 });

const appointmentApi = new AppointmentApi();

const route = useRoute();

const token = route?.query?.token;
const id = route?.params?.id;

const info = ref<IAppointmentJoinInfo | null>(null);

const baseUrl = `${BASE_URL}`;

const img = computed(() => {
  return info.value?.tenantConfig.logo ? `${baseUrl}/${info.value.tenantConfig.logo}` : undefined;
});

const timeRemainingTemplate = '{{timeRemaining}}';

const snippets = computed(() => {
  return (
    info.value?.snippets ?? {
      header_success: 'Termin beitreten',
      header_error: 'Beitreten nicht möglich',
      join_button: 'Meeting starten',
      start_countdown: 'Termin beginnt in {{timeRemaining}}',
    }
  );
});

const timeRemaining = computed(() => {
  if (!info.value || !info.value.start) return '-';
  let diff = (info.value.start - timestamp.value) / 1000;
  if (diff < 0) return '-';
  const days = Math.floor(diff / 86400);
  diff %= 86400;
  const hours = Math.floor(diff / 3600);
  diff %= 3600;
  const minutes = Math.floor(diff / 60);
  const seconds = Math.floor(diff % 60);

  if (days > 0) return `${days} Tagen und ${hours} Stunden`;
  if (hours > 0) return `${hours} Stunden und ${minutes} Minuten`;
  return `${minutes} Minuten und ${seconds} Sekunden`;
});

const joinMeeting = () => {
  const url = info.value?.redirectUrl ?? info.value?.joinUrl;
  if (url) window.location.href = url;
};

const loadInfo = () => {
  appointmentApi.joinInfo(String(id), String(token)).then(
    (value) => {
      info.value = value.data;

      if (info.value.redirectUrl) joinMeeting();

      if (info.value.start) {
        const timeRemaining = info.value.start - timestamp.value;
        if (timeRemaining > 120000) useTimeoutFn(loadInfo, 120000);
        else useTimeoutFn(loadInfo, timeRemaining);
      }
    },
    () => {
      info.value = {
        success: false,
        message: 'Es ist ein unbekannter Fehler aufgetreten',
        tenantConfig: {
          color: '#0093d7',
        },
      };
    },
  );
};

const hexToRgb = (hex: string) => {
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return r + ',' + g + ',' + b;
};

watch(info, () => {
  if (info.value) {
    const colorRGB = hexToRgb(info.value.tenantConfig.color.substring(1));
    const fontColor = hexToRgb(info.value.tenantConfig.fontColor.substring(1));
    document.body.style.setProperty('--ntcxat-color-first', colorRGB);
    document.body.style.setProperty('--ntcxat-color-text', fontColor);
  }
});

loadInfo();
</script>
<style>
.callback {
  width: 100vw;
}
</style>
