<script setup lang="ts">
import Menu from 'primevue/menu';

import { ref } from 'vue';
import { IAppointment } from 'types/appointment';
import { IMenuItems } from 'types/menu-items';

const emit = defineEmits<{
  (e: 'clickMenu', item: IMenuItems, appointment: IAppointment): void
}>();

const props = defineProps<{
  menuItems: IMenuItems[],
  appointment: IAppointment,
}>();

const menu = ref();

const toggleMenu = (event: MouseEvent) => {
  menu.value.toggle(event);
};
</script>

<template>
  <span class="pi pi-ellipsis-v cursor-pointer" @click="toggleMenu"></span>
  <Menu ref="menu" :model="props.menuItems" :popup="true">
    <template #item="{ item }">
      <a
        class="p-menuitem-link"
        :class="item.classes"
        @click="emit('clickMenu', item, props.appointment)"
      >{{ item.label }}</a>
    </template>
  </Menu>
</template>
