import axios from "axios";
import { useAuth } from "~/store/auth";

/**
 * axios interceptor https://axios-http.com/docs/interceptors
 * this "plugin" is used as a custom axios plugin
 * it is used to add the auth token to the request header
 * and to handle the 401 response, which is a token refresh
 */


axios.interceptors.request.use(function (request) {
  if (!request || !request.headers) return;
  // get the auth token from the store

  const authStore = useAuth();
  const token = authStore.token;
  if (token && !request.headers.Authorization) {
    // add the token to the request header
    request.headers.Authorization = `Bearer ${token}`;
  }

  return request;
});
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const authStore = useAuth();
    // if the error is a 401 response, refresh the token
    // and retry the request
    if (authStore.isAuthenticated) {
      if (error.response.status === 401) {
        if (await authStore.refresh()) {
          const token = authStore.token;
          error.response.config.headers.Authorization = `Bearer ${token}`;
          return axios.request(error.response.config);
        }
      }
    }
    return Promise.reject(error);
  }
);

export default axios;
