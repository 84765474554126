import { Roles } from 'types/roles';
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import { useAuth } from '~/store/auth';

/**
 * @param to
 * @param from
 * @param next
 * Custom navigation guard
 * checks if the route requires authentication and if the user is authenticated
 * if not, redirects to the login page
 */
async function authGuard(to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) {
  const authStore = useAuth();
  authStore.getRefreshToken();
  const isAuthenticated = await authStore.getToken();

  if (to.meta.requiresAuth && to.name !== 'login' && !isAuthenticated) {
    next({ name: 'login' });
    return;
  }
  if (to.meta.requiresAuth && !hasRoleAccess(to.meta.requiredRole as Roles[], authStore.user?.role)) {
    next({ name: 'appointments' });
  } else {
    next();
    return;
  }
}

/**
 *
 * @param role role to check against
 * @param userRole role of the user
 * @returns {boolean} true if the users role is equal or higher than the required role
 * @description helper function to check roles
 */
function hasRoleAccess(roles: Roles[], userRole?: Roles): boolean {
  if (!userRole) return false;
  if (roles.some((role) => role === Roles[userRole])) {
    return true;
  }
  return false;
}

async function loginRediectGuard(
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext,
) {
  const authStore = useAuth();
  const isAuthenticated = await authStore.getToken();
  if (isAuthenticated && to.name !== 'login') {
    next();
  } else if (isAuthenticated && to.name === 'login') {
    next({ name: 'appointments' });
  } else {
    next();
  }
}

export { authGuard, loginRediectGuard };
