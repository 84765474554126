<script setup lang="ts">
import { useAuth } from '@/store/auth';
import { watchEffect } from 'vue';

import TenantConfigApi from '@/services/TenantConfigApi';
import type TenantConfig from 'types/tenant-config';

const authStore = useAuth();
watchEffect(async () => {
  if (authStore.isAuthenticated) {
    const user = await authStore.getUser();
    if (user && user.tenant && user.tenant.id) {
      const tenantId = user.tenant.id;

      const hexToRgb = (hex: string) => {
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;

        return r + ',' + g + ',' + b;
      };
      const tenantConfig: TenantConfig = (await new TenantConfigApi().getConfig(tenantId)).data;
      if (tenantConfig.color) {
        const color = tenantConfig.color;

        const colorRGB = hexToRgb(color.substring(1));

        document.body.style.setProperty('--ntcxat-color-first', colorRGB);
      }
      if (tenantConfig.fontColor) {
        const fontcolor = tenantConfig.fontColor;
        document.body.style.setProperty('--ntcxat-color-text', hexToRgb(fontcolor.substring(1)));
      }
    }
  }
});
</script>

<template>
  <router-view v-slot="{ Component }" :key="$route.matched[0]?.name">
    <template v-if="Component">
      <transition name="fade" mode="out-in">
        <keep-alive>
          <suspense>
            <component :is="Component" />
          </suspense>
        </keep-alive>
      </transition>
    </template>
  </router-view>
</template>

<style></style>
