<script lang="ts" setup>
import PageHeading from '@/components/PageHeading/PageHeading.vue';

import { useRouter } from 'vue-router';

import { ref, computed } from 'vue';

import { useUserStore } from '@/store/user';
import { useAuth } from '@/store/auth';

import { useInbound } from '@/store/inbound';
import { IInbound } from 'types/inbound';

import { UserCreate } from 'types/user';

import { required, email, minLength } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';

import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import MultiSelect from 'primevue/multiselect';

import useMessages from '@/composables/messages';
import { MessageType } from 'types/message';
import UserApi from '@/services/UserApi';

const { addToast } = useMessages();

const router = useRouter();

const userStore = useUserStore();
const authStore = useAuth();

const id = (await userStore.getUserDetail((await authStore.getUser()).id)).id;

const user = await userStore.getUserDetail(id);
const userInboundIds = user.inbounds.map((i: IInbound) => i.id);
const formData = ref({
  firstName: user.firstName,
  lastName: user.lastName,
  email: user.email,
  inbounds: user.inbounds,
  inboundIds: userInboundIds,
});

const inboundStore = useInbound();
const inboundChannels = ref(
  (await inboundStore.getAll())
    .filter((inbound) => inbound.type !== 'GROUP')
    .map((inboundChannel: IInbound) => {
      return {
        label: inboundChannel.name,
        value: inboundChannel.id,
      };
    }),
);

const validationsForm = { formData: { email: { email } } };
const $vForm = useVuelidate(validationsForm.formData, formData);

const isDisabledBtn = computed(() => !formData.value.firstName || !formData.value.lastName);

const handleSubmit = async () => {
  $vForm.value.$validate();

  const userCreate: UserCreate = {
    email: formData.value.email,
    firstName: formData.value.firstName,
    lastName: formData.value.lastName,
    role: user.role,
    inbounds: formData.value.inboundIds,
    agentId: user.agentId,
    tenant: user.tenant?.id,
  };

  const isSuccess = await userStore.updateUser(String(user.id), userCreate);

  if (isSuccess) {
    addToast({
      type: MessageType.Success,
      content: `Benutzer ändern erfolgreich`,
    });

    router.push('/profile/my-profile');
  } else {
    addToast({
      type: MessageType.Error,
      content: `Benutzer ändern fehlgeschlagen`,
    });
  }
};

const passwordInput = ref({ password: undefined as unknown as string });

const validationsPassword = { passwordInput: { password: { required, minLength: minLength(6) } } };
const $vPassword = useVuelidate(validationsPassword.passwordInput, passwordInput);

const resetPassword = ref(false);

const userApi = new UserApi();
const changePassword = async () => {
  $vPassword.value.$validate();

  if (!$vPassword.value.$invalid) {
    if (id && passwordInput.value) {
      const isSuccess = await userApi.setPassword(id, passwordInput.value);

      if (isSuccess) {
        addToast({
          type: MessageType.Success,
          content: `Passwort ändern erfolgreich`,
        });
      } else {
        addToast({
          type: MessageType.Error,
          content: `Passwort ändern fehlgeschlagen`,
        });
      }
    }
    resetPassword.value = false;
  }
};

const showResetPassword = () => {
  resetPassword.value = !resetPassword.value;
};
</script>
<template>
  <div class="page-content w-full">
    <PageHeading title="Mein Profil" />
    <div class="container-wrapper">
      <div class="grid grid-cols-1 md:grid-cols-2 px-4 md:px-8 mt-14 md:mt-6">
        <div class="field">
          <div class="text-l text-black mb-4 font-semibold">Name</div>
          <div class="mb-6">Wählen Sie den Namen des Benutzers aus.</div>
          <div class="grid gap-x-6 md:grid-cols-4 md:grid-rows-1 grid-cols-1 grid-rows-2">
            <div>
              <InputText
                v-model="formData.firstName"
                placeholder="Vorname"
                class="w-full h-9 text-s placeholder:text-light-grey p-2"
              />
            </div>
            <div>
              <InputText
                v-model="formData.lastName"
                placeholder="Nachname"
                class="w-full h-9 text-s placeholder:text-light-grey p-2"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 px-4 md:px-8 mt-14 md:mt-6">
        <div class="field">
          <div class="text-l text-black mb-4 font-semibold">E-Mail</div>
          <div class="mb-6">Wählen Sie die E-Mail Adresse des Benutzers aus.</div>
          <div class="grid gap-x-4 gap-y-6 grid-cols-1 md:grid-cols-2">
            <div class="grid grid-cols-1">
              <div :class="{ 'validation-error': $vForm['email'].$error }">
                <InputText
                  v-model="formData.email"
                  placeholder="E-Mail"
                  type="email"
                  class="w-full h-9 text-s placeholder:text-light-grey p-2"
                />
              </div>
              <div :style="{ visibility: $vForm['email'].$dirty && $vForm['email'].$invalid ? 'visible' : 'hidden' }">
                <div class="error ml-1 mt-0.5">Keine gültige E-Mail Adresse</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 px-4 md:px-8 mt-14 md:mt-6">
        <div class="field">
          <div class="text-l text-black mb-4 font-semibold">Abonnierte Kalender</div>
          <div class="mb-6">Wählen Sie alle Kalender, dessen Termine der Benutzer sehen soll, aus.</div>
          <div class="grid gap-x-4 gap-y-6 grid-cols-1 md:grid-cols-2">
            <MultiSelect
              v-model="formData.inboundIds"
              :options="inboundChannels"
              :filter="true"
              option-label="label"
              option-value="value"
              placeholder="Kalender"
            />
          </div>
        </div>
      </div>
      <div class="mt-6 md:mt-14 px-4 md:px-8 flex items-start">
        <div class="grid grid-cols-1">
          <div>
            <Button
              :label="resetPassword ? 'Abbrechen' : 'Passwort ändern'"
              class="h-9 p-button-outlined p-button-secondary mr-2 text-s text-black"
              @click="showResetPassword"
            />

            <Password
              v-if="resetPassword"
              v-model="passwordInput.password"
              placeholder="Neues Passwort"
              :toggle-mask="true"
              :feedback="false"
              class="h-9 mr-2 text-s placeholder:text-light-grey"
            ></Password>

            <Button
              v-if="resetPassword"
              label="Speichern"
              class="h-9 p-button-outlined p-button-secondary text-s text-black"
              @click="changePassword"
            />
          </div>
          <div
            :style="{
              visibility:
                $vPassword['password'].$dirty && $vPassword['password'].$invalid && resetPassword
                  ? 'visible'
                  : 'hidden',
            }"
          >
            <div class="error ml-1 mt-0.5">Kein gültiges Passwort (mindestens 6 Zeichen)</div>
          </div>
        </div>
      </div>
      <div class="mt-6 md:mt-14 px-4 md:px-8 flex items-center">
        <Button
          label="Verwerfen"
          class="p-button-outlined p-button-secondary mr-2 text-s text-black"
          @click="router.go(-1)"
        />
        <Button
          label="Speichern"
          :disabled="isDisabledBtn"
          class="p-button-primary bg-first hover:!bg-first border-none text-s"
          @click="handleSubmit"
        />
      </div>
    </div>
  </div>
</template>
