<script lang="ts" setup>
import { computed, ref, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ITenant, ITenantDevice, ITenantOptions, ITenantDeviceOption } from 'types/tenant';
import { BASE_URL } from 'types/config';

import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputSwitch from 'primevue/inputswitch';
import Dropdown from 'primevue/dropdown';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColorPicker from 'primevue/colorpicker';
import FileUpload, { FileUploadBeforeSendEvent, FileUploadErrorEvent } from 'primevue/fileupload';

import { useTenantStore } from '@/store/tenant';
import { useAuth } from '@/store/auth';

import PageHeading from '@/components/PageHeading/PageHeading.vue';
import BaseModal from '@/components/BaseModal/BaseModal.vue';

import useMessages from '@/composables/messages';
import { MessageType } from 'types/message';

import RadioButton from 'primevue/radiobutton';

const { addToast } = useMessages();

const tenantStore = useTenantStore();
const authStore = useAuth();

const route = useRoute();
const router = useRouter();
const pageName = String(route.name);
const routeAction = route?.meta.action;
const tenantId: string | string[] = route?.params?.id;

const submitMode = routeAction == 'edit' ? 'Speichern' : 'Erstellen';

const openModal = ref(false);
const overrideMail = ref(false);

let formData = reactive<ITenant>({
  customerId: 0,
  name: '',
  joinlinkTemplateId: 0,
  spaceTemplateId: 0,
  color: '#0093d7',
  fontColor: '#000000',
  logo: '',
  devices: [],
  mailHost: '',
  mailPort: 2525,
  mailUser: '',
  mailPassword: '',
  mailSender: '',
  mailSecurity: 'PLAIN',
});

const mailSecurityOptions = [
  { label: 'PLAIN', value: 'PLAIN' },
  { label: 'STARTTLS', value: 'STARTTLS' },
  { label: 'SSL', value: 'SSL' },
];

const mailSettingsChecked = ref(false);
const mailSettingsError = ref<string | null>(null);
const mailSettingsSuccess = ref(false);

const canCheckMailSettings = computed(() => {
  return formData.mailHost && !mailSettingsChecked.value;
});

const checkMailSettings = async () => {
  try {
    mailSettingsChecked.value = true;
    mailSettingsSuccess.value = false;
    mailSettingsError.value = null;
    await tenantStore.checkMailSettings(formData);
    mailSettingsSuccess.value = true;
  } catch (error: any) {
    mailSettingsError.value = error?.response?.data?.message || 'Unbekannter Fehler';
  }
};

const inputTextColor = ref('#0093d7');

if (routeAction === String('edit')) {
  const tenant: ITenant = await tenantStore.getTenantDetail(String(tenantId));
  await tenantStore.getTenantOptions();
  const deviceOptions = await tenantStore.getTenantDevices(String(tenantId));
  formData = reactive(tenant);
  formData.devices = deviceOptions;
  inputTextColor.value = formData.color ||= '';
  formData.mailSecurity ||= 'PLAIN';
  formData.mailPort ||= 2525;
  overrideMail.value = !!formData.mailHost;
}

const handleSubmit = async () => {
  let isSuccess: boolean;
  let action: string;

  const clone = Object.assign({}, formData);
  if (formData.color && formData.color[0] !== '#') {
    clone.color = '#' + formData.color;
  }

  if (!overrideMail.value || !formData.mailHost) {
    clone.mailHost = '';
    clone.mailPort = 2525;
    clone.mailUser = '';
    clone.mailPassword = '';
    clone.mailSender = '';
    clone.mailSecurity = 'PLAIN';
  }

  if (routeAction === 'add' && !tenantId) {
    isSuccess = await tenantStore.createTenant(clone);
    action = 'erstellen';
  } else {
    isSuccess = await tenantStore.updateTenant(String(tenantId), clone);
    action = 'ändern';
  }

  if (isSuccess) {
    addToast({
      type: MessageType.Success,
      content: `Tenant ${action} erfolgreich`,
    });

    router.push('/admin/tenant');
  } else {
    addToast({
      type: MessageType.Error,
      content: `Tenant ${action} fehlgeschlagen`,
    });
  }
};

const onClickDelete = () => {
  openModal.value = true;
};

const onHandleDelete = async () => {
  if (!formData.id) return;
  const isSuccess: boolean = await tenantStore.deleteTenant(formData.id);
  openModal.value = false;

  if (isSuccess) {
    addToast({
      type: MessageType.Success,
      content: `Tenant löschen erfolgreich`,
    });
    router.push('/admin/tenant');
  } else {
    addToast({
      type: MessageType.Error,
      content: `Tenant löschen fehlgeschlagen`,
    });
  }
};

const isDisabledBtn = computed(
  () => !formData.name || !formData.customerId || !formData.joinlinkTemplateId || !formData.spaceTemplateId,
);

/**
 *
 * @param key of ITenantOptions : string
 * @returns {ITenantOptions<T>}
 */
function getOptions(key: keyof ITenantOptions) {
  return tenantStore.options[key];
}
/**
 *
 * @param key of ITenerantOptions : String
 * set loading indecator for specific dropdown by key
 * loads the options if not loaded
 */
async function loadOptions(key: keyof ITenantOptions) {
  // if options for key already exist stop loading
  if (tenantStore.options[key].length) return;
  loading.options[key] = true;
  await tenantStore.getTenantOptions();
  loading.options[key] = false;
}
// reactive loading object for each dropdown
const loading = reactive({
  options: { customers: false, joinlinkTemplates: false, spaceTemplates: false },
});

// TODO: check primevue event
function beforeUpload(event: FileUploadBeforeSendEvent) {
  // set auth header
  event.xhr.setRequestHeader('Authorization', 'Bearer ' + authStore.token);
}
function uploaded(event: FileUploadErrorEvent) {
  formData.logo = event.xhr.response;
  addToast({
    type: MessageType.Success,
    content: `Upload erfolgreich`,
  });
}

function uploadError() {
  addToast({
    type: MessageType.Error,
    content: `Upload fehlgeschlagen`,
  });
}

// function to reset input text to form data color if input is no valid hex color
const inputTextColorChange = (event) => {
  if (inputTextColor.value !== formData.color) {
    if (!/^#[0-9A-F]{6}$/i.test(event.target.value)) {
      inputTextColor.value = formData.color;
    } else {
      formData.color = event.target.value;
    }
  }
};

// set input text value to color picker value
const colorPicked = (event) => {
  if (event.value[0] !== '#') {
    inputTextColor.value = '#' + event.value;
  } else {
    inputTextColor.value = event.value;
  }
};
</script>

<template>
  <div class="page-content w-full user-add">
    <PageHeading class="mb-5" :title="pageName" />
    <div class="container-wrapper">
      <div class="grid grid-cols-1 md:grid-cols-2 px-4 md:px-8 mt-14 md:mt-6 gap-16">
        <div class="field">
          <div class="text-l text-black mb-4 font-semibold">Tenant Name</div>
          <div class="mb-6">Vergeben Sie den Namen des Tenants</div>
          <div class="grid">
            <InputText
              v-model="formData.name"
              placeholder="Tenant Name"
              class="w-full text-s placeholder:text-light-grey"
            />
          </div>
        </div>
        <div class="field">
          <div class="text-l text-black mb-4 font-semibold">VQ-Customer-ID</div>
          <div class="mb-6">Wählen Sie den entsprechenden VQ-Customer aus</div>
          <div class="grid">
            <Dropdown
              v-model="formData.customerId"
              class="w-full text-s placeholder:text-light-grey"
              :options="getOptions('customers')"
              option-label="name"
              option-value="id"
              placeholder="Customer ID"
              :loading="loading.options.customers"
              @click="loadOptions('customers')"
            />
          </div>
        </div>
        <div class="field">
          <div class="text-l text-black mb-4 font-semibold">VQ-Joinlink-Template-ID</div>
          <div class="mb-6">Wählen Sie das entsprechende VQ-Joinlink-Template aus</div>
          <div class="grid">
            <Dropdown
              v-model="formData.joinlinkTemplateId"
              class="w-full text-s placeholder:text-light-grey"
              :options="getOptions('joinlinkTemplates')"
              placeholder="Join-Link Template ID"
              option-label="name"
              option-value="id"
              :loading="loading.options.joinlinkTemplates"
              @click="loadOptions('joinlinkTemplates')"
            />
          </div>
        </div>
        <div class="field">
          <div class="text-l text-black mb-4 font-semibold">VQ-Space-Template-ID</div>
          <div class="mb-6">Wählen Sie das entsprechende VQ-Space-Template aus</div>
          <div class="grid">
            <Dropdown
              v-model="formData.spaceTemplateId"
              class="w-full text-s placeholder:text-light-grey"
              :options="getOptions('spaceTemplates')"
              option-label="name"
              option-value="id"
              placeholder="Space Template ID"
              :loading="loading.options.spaceTemplates"
              @click="loadOptions('spaceTemplates')"
            />
          </div>
        </div>
        <div class="field">
          <div class="text-l text-black mb-4 font-semibold">Tenant Primär Farbe</div>
          <div class="mb-6">Vergeben Sie die Primär Farbe des Tenants</div>
          <div class="flex">
            <ColorPicker
              v-model="formData.color"
              placeholder="Tenant Primär Farbe"
              class="w-full text-s placeholder:text-light-grey"
              @change="colorPicked"
            />
            <InputText v-model="inputTextColor" @change="inputTextColorChange"></InputText>
          </div>
        </div>
        <div class="field">
          <div class="text-l text-black mb-4 font-semibold">Schriftfarbe</div>
          <div class="mb-6">
            Vergeben Sie die Schriftfarbe für den Tenant: weiß oder schwarz. Die Schriftfarbe wird auf dem Schaltflächen
            verwendet und sollte zur Primär Farbe einen deutlichen Kontrast haben.
          </div>
          <div class="flex gap-2">
            <div
              v-for="color in [
                { name: 'Schwarz', key: '#000000' },
                { name: 'Weiß', key: '#FFFFFF' },
              ]"
              :key="color.key"
              class="flex align-items-center"
            >
              <RadioButton v-model="formData.fontColor" :input-id="color.key" name="dynamic" :value="color.key" />
              <label :for="color.key" class="ml-2 cursor-pointer">{{ color.name }}</label>
            </div>
          </div>
        </div>
        <div class="field">
          <div class="text-l text-black mb-4 font-semibold">Tenant Logo</div>
          <div class="mb-6">Laden Sie ein Logo für den Tenant hoch</div>
          <div class="grid">
            <FileUpload
              mode="basic"
              name="logo"
              accept="image/*"
              class="p-button-primary bg-first hover:!bg-first border-none text-s"
              :max-file-size="1000000000"
              :url="BASE_URL + '/tenant/upload'"
              :auto="true"
              :choose-label="formData.logo || 'Auswahl'"
              @before-send="beforeUpload"
              @upload="uploaded"
              @error="uploadError"
            />
          </div>
        </div>
        <div class="field col-span-full">
          <div class="text-l text-black mb-4 font-semibold">SMTP Konfiguration überschreiben</div>
          <div class="mb-6">
            <InputSwitch id="overrideMail" v-model="overrideMail" :class="!overrideMail ? 'disabled' : ''" />
          </div>
          <div v-if="overrideMail" class="mb-6">
            Konfigurieren Sie die SMTP-Einstellungen des Tenants. Die Einstellungen überschreiben den Default.
          </div>
          <div v-if="overrideMail" class="mb-6">
            <Button
              :disabled="!canCheckMailSettings"
              label="STMP Konfiguration prüfen"
              class="p-button-outlined p-button-secondary mr-2 text-s text-black"
              @click="checkMailSettings()"
            />
          </div>
          <div v-if="overrideMail && mailSettingsChecked" class="mb-6">
            <span v-if="mailSettingsError" class="text-red"
              >Fehlerhafte SMTP Konfiguration: {{ mailSettingsError }}</span
            >
            <span v-if="mailSettingsSuccess" class="text-green">SMTP Prüfung erfolgreich</span>
          </div>
          <div v-if="overrideMail" class="grid gap-4 md:grid-cols-2">
            <div>
              <label for="smtpHost">SMTP Host</label>
              <InputText
                id="smtpHost"
                v-model="formData.mailHost"
                placeholder="SMTP Host"
                class="w-full text-s placeholder:text-light-grey mt-2"
                @change="mailSettingsChecked = false"
              />
            </div>
            <div>
              <label for="smtpPort">SMTP Port</label>
              <InputText
                id="smtpPort"
                v-model="formData.mailPort"
                type="number"
                placeholder="SMTP Port"
                class="w-full text-s placeholder:text-light-grey mt-2"
                @change="mailSettingsChecked = false"
              />
            </div>
            <div>
              <label for="smtpUsername">SMTP Username</label>
              <InputText
                id="smtpUsername"
                v-model="formData.mailUser"
                placeholder="SMTP Username"
                class="w-full text-s placeholder:text-light-grey mt-2"
                @change="mailSettingsChecked = false"
              />
            </div>
            <div>
              <label for="smtpPassword">SMTP Password</label>
              <InputText
                id="smtpPassword"
                v-model="formData.mailPassword"
                type="password"
                placeholder="SMTP Password"
                class="w-full text-s placeholder:text-light-grey mt-2"
                @change="mailSettingsChecked = false"
              />
            </div>
            <div>
              <label for="smtpSecurity">SMTP Sicherheit</label>
              <Dropdown
                id="smtpSecurity"
                v-model="formData.mailSecurity"
                option-label="label"
                option-value="value"
                class="w-full text-s placeholder:text-light-grey"
                :options="mailSecurityOptions"
                @change="mailSettingsChecked = false"
              />
            </div>
            <div>
              <label for="smtpSender">SMTP Sender</label>
              <InputText
                id="smtpSender"
                v-model="formData.mailSender"
                placeholder="SMTP Sender"
                class="w-full text-s placeholder:text-light-grey mt-2"
              />
            </div>
          </div>
        </div>
      </div>

      <div v-if="routeAction === String('edit')" class="grid grid-cols-1 px-4 md:px-8 mt-14 md:mt-6 w-full">
        <div class="field mt-6 md:mt-12">
          <div class="text-l text-black mb-4 font-semibold">Geräte</div>
          <DataTable v-if="formData.devices?.length !== 0" responsive-layout="scroll" :value="formData.devices">
            <Column header="Active">
              <template #body="slotProps">
                <InputSwitch v-model="slotProps.data.active" :class="!slotProps.data.active ? 'disabled' : ''" />
              </template>
            </Column>
            <Column field="technicalName" header="ID"></Column>
            <Column header="Alias">
              <template #body="slotProps">
                <InputText
                  v-model="slotProps.data.name"
                  class="w-full min-w-min h-9 text-s placeholder:text-light-grey p-2 rounded text-dark-grey"
                  :placeholder="slotProps.data.technicalName ?? ''"
                />
              </template>
            </Column>
          </DataTable>

          <div v-else>
            <span class="text-red">Keine Geräte verfügbar</span>
          </div>
        </div>
      </div>

      <div class="mt-6 md:mt-14 px-4 md:px-8 flex items-center">
        <i
          v-if="routeAction === 'edit'"
          class="pi pi-trash text-l cursor-pointer text-red hover:text-red p-2 mr-2 md:mr-4"
          @click="onClickDelete()"
        ></i>
        <Button
          label="Abbrechen"
          class="p-button-outlined p-button-secondary mr-2 text-s text-black"
          @click="router.go(-1)"
        />
        <Button
          :disabled="isDisabledBtn"
          class="p-button-primary bg-first hover:!bg-first border-none text-s"
          @click="handleSubmit"
          >{{ submitMode }}</Button
        >
      </div>
    </div>
    <teleport to="body">
      <BaseModal :data-modal="formData" :open="openModal" @update:open="openModal = $event" @submit="onHandleDelete">
        <template #body="slotProps">
          <div class="flex items-center">
            <i class="pi pi-exclamation-triangle mr-3 text-red text-xl" />
            <span>
              Möchten Sie den Tenant
              <strong>{{ slotProps.slotProps.name }}</strong>
              wirklich löschen?
            </span>
          </div>
        </template>
      </BaseModal>
    </teleport>
  </div>
</template>

<style scoped>
.devices:nth-child(even) {
  @apply bg-gray-f3;
}
</style>
