import { defineStore } from 'pinia';
import LocalBranchApi from '@/services/LocalBranchApi';
import { ILocalBranch } from 'types/local-branch';

const branchApi = new LocalBranchApi();
export const useLocalBranch = defineStore('local-branch', {
  state: () => {
    return {
      listAll: <ILocalBranch[]>[],
    };
  },
  actions: {
    async getAll() {
      try {
        const { data } = await branchApi.getAll();
        this.listAll = data;
        return data;
      } catch (e) {
        return e;
      }
    },
    async create(body: object = {}) {
      try {
        await branchApi.create(body);
        return true;
      } catch (e) {
        return false;
      }
    },
    async getDetail(id: string) {
      try {
        const { data } = await branchApi.detail(id);
        return data;
      } catch (e) {
        return e;
      }
    },
    async update(id: string, body: object = {}) {
      try {
        await branchApi.update(id, body);
        return true;
      } catch (e) {
        return false;
      }
    },
    async delete(id: string) {
      try {
        await branchApi.remove(id);
      } catch (e) {
        return e;
      }
    },
  },
});
