import BaseApi from './BaseApi';
import { BASE_URL } from 'types/config';
import { ISnippet } from 'types/snippet';
import { AxiosPromise } from 'axios';

export default class SnippetApi extends BaseApi {
  private baseURL = `${BASE_URL}/snippet`;
  constructor() {
    super();
  }

  public getAll(): AxiosPromise<ISnippet[]> {
    return this.get(this.baseURL);
  }

  public create(body: { snippetId: string; value: string; tenantId: string }) {
    return this.post(this.baseURL, body);
  }

  public detail(id: string): AxiosPromise<ISnippet> {
    return this.get(`${this.baseURL}/${id}`);
  }

  public getDefaults(): AxiosPromise<ISnippet[]> {
    return this.get(`${this.baseURL}/defaults`);
  }

  public getVars(id: string): AxiosPromise<string[]> {
    return this.get(`${this.baseURL}/vars/${id}`);
  }

  public remove(id: string): AxiosPromise<void> {
    return this.delete(`${this.baseURL}/${id}`);
  }
  public update(id: string, body: { snippetId: string; value: string; tenantId: string }): AxiosPromise<ISnippet> {
    return this.patch(`${this.baseURL}/${id}`, body);
  }
}
