import BaseApi from './BaseApi';
import { AxiosPromise } from 'axios';
import { BASE_URL } from 'types/config';
import { IAppointment, IAppointmentUpdate, IAppointmentOptions, IAppointmentJoinInfo } from 'types/appointment';
export default class AppointmentApi extends BaseApi {
  private baseURL: string = BASE_URL;
  constructor() {
    super();
  }

  public getAll(): AxiosPromise<IAppointment[]> {
    return this.get(`${this.baseURL}/appointment`);
  }

  public add(body: IAppointment) {
    return this.post(`${this.baseURL}/appointment`, body);
  }

  public detail(id: string): AxiosPromise<IAppointment[]> {
    return this.get(`${this.baseURL}/appointment/${id}`);
  }

  public remove(id: string): AxiosPromise<void> {
    return this.delete(`${this.baseURL}/appointment/${id}`);
  }

  public cancel(id: string): AxiosPromise<void> {
    return this.post(`${this.baseURL}/appointment/${id}/cancel`, {});
  }

  public refuse(id: string): AxiosPromise<void> {
    return this.post(`${this.baseURL}/appointment/${id}/refuse`, {});
  }

  public update(id: string, body: IAppointmentUpdate): AxiosPromise<IAppointment[]> {
    return this.patch(`${this.baseURL}/appointment/${id}`, body);
  }

  public options(id: string): AxiosPromise<IAppointmentOptions> {
    return this.get(`${this.baseURL}/appointment/${id}/options`);
  }

  public joinInfo(id: string, token: string): AxiosPromise<IAppointmentJoinInfo> {
    return this.get(`${this.baseURL}/appointment/${id}/join`, {
      params: { token },
    });
  }
}
