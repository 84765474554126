<script lang="ts" setup>
import Sidebar from '@/components/layouts/Sidebar.vue';
import { Roles } from 'types/roles';
import { computed } from 'vue';
import { useAuth } from '~/store/auth';
const authStore = useAuth();
const userRole = computed(() => authStore.user?.role);

const menuItems = [
  {
    label: 'Kalender',
    to: '/admin/inbound',
    icon: 'pi pi-calendar',
  },
  {
    label: 'Benutzer',
    to: '/admin/user',
    icon: 'pi pi-user',
  },
  {
    label: 'Filialen',
    to: '/admin/branch',
    icon: 'pi pi-home',
  },
  {
    label: 'Textbausteine',
    to: '/admin/snippet',
    icon: 'pi pi-list',
  },
  {
    label: 'Tenants',
    to: '/admin/tenant',
    icon: 'pi pi-users',
    visible: () => {
      // TODO Remove hardoced enum. Difference between names and values of enums, stucking if get value by key...
      return [Roles.superAdmin].some((role) => role === Roles[userRole.value]);
    },
  },
  // {
  //   label: 'Endgeräte',
  //   command: () => {
  //     router.push('/users');
  //   },
  // },
  // {
  //   label: 'Auswertungen',
  //   command: () => {
  //     router.push('/users');
  //   },
  // },
];
</script>
<template>
  <div class="container-fluid flex">
    <Sidebar :menu-items="menuItems" />
    <router-view v-slot="{ Component }" :key="$route.path">
      <template v-if="Component">
        <transition name="fade" mode="out-in">
          <keep-alive>
            <suspense>
              <component :is="Component" />
            </suspense>
          </keep-alive>
        </transition>
      </template>
    </router-view>
  </div>
</template>
