<template>
  <!-- TODO: check and remove  -->
  <transition name="fade" mode="out-in">
    <keep-alive>
      <suspense>
        <MainHeader :img="img" />
        <template #fallback>
          <div class="grid place-content-center">
            <ProgressSpinner />
          </div>
        </template>
      </suspense>
    </keep-alive>
  </transition>

  <div class="flex items-center justify-center callback">
    <div v-if="type === 'confirm'">
      <div class="flex items-center justify-center">
        <strong>Termin bestätigt!</strong>
      </div>
      <div>Sie erhalten die Zugangsinformationen, sobald Ihnen ein Agent zugewiesen wurde.</div>
    </div>
    <div v-if="type === 'cancel'">
      <div class="flex items-center justify-center">
        <strong>Termin abgesagt!</strong>
      </div>
    </div>
    <div v-if="type === 'error'">
      <div class="flex items-center justify-center">
        <strong>Fehler bei der Buchung!</strong>
      </div>
      <div>Bitte versuchen Sie es später erneut.</div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { BASE_URL } from 'types/config';
import { useRoute } from 'vue-router';

import MainHeader from '@/components/layouts/MainHeader.vue';
import ProgressSpinner from 'primevue/progressspinner';

const route = useRoute();

const type = route?.params?.type;

const img = (BASE_URL + '/' + route?.query?.logo) as string;
const color = route?.query?.color as string;
const fontcolor = `${route?.query?.fontcolor || route?.query?.fontcolor || '#FFFFFF'}`;
if (color) {
  const hexToRgb = (hex: string) => {
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return r + ',' + g + ',' + b;
  };

  const colorRGB = hexToRgb(color.substring(1));

  document.body.style.setProperty('--ntcxat-color-first', colorRGB);
  document.body.style.setProperty('--ntcxat-color-text', hexToRgb(fontcolor.substring(1)));
}
</script>
<style>
.callback {
  width: 100vw;
}
</style>
