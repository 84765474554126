<script lang="ts" setup>
import { computed } from 'vue';
import Cleave from 'vue-cleave-component';

const props = defineProps({
  placeholder: {
    type: String,
    required: false,
    default: 'hh:mm',
  },
  value: {
    type: String,
    required: false,
    default: '',
  },
  delimiter: {
    type: String,
    required: false,
    default: ':',
  },
  maskPattern: {
    type: Array,
    required: false,
    default: () => ['h', 'm'],
  },
  invalid: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const emit = defineEmits(['input']);

const options = {
  time: true,
  delimiter: props.delimiter,
  timePattern: props.maskPattern,
  onValueChanged: function (e: { target: { name: string; rawValue: ''; value: string } }) {
    emit('input', e.target.value);
  },
};

const inputVal = computed(() => props.value);
</script>

<template>
  <cleave
    v-model="inputVal"
    :class="{'p-invalid': invalid}"
    :options="options"
    class="p-inputtext p-component"
    :placeholder="placeholder"
    name="input-time"
  />
</template>
