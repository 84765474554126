<script setup lang="ts">
import { computed, ref, watchEffect } from 'vue';
import defaultLogo from '@/assets/web-consult-logo.svg';

// Vue imports
import { useAuth } from '@/store/auth';
import { useRouter } from 'vue-router';

import { Roles } from 'types/roles';

import TenantConfigApi from '@/services/TenantConfigApi';
import type TenantConfig from 'types/tenant-config';

const props = defineProps<{
  img?: string;
}>();

const authStore = useAuth();
const router = useRouter();

const loggedIn = computed(() => authStore.isAuthenticated);

function handleLogout() {
  authStore.logout();
  router.push('/login');
}

const role = computed(() => authStore.user?.role);

const image = ref<string>(undefined as unknown as string);
if (props.img) {
  image.value = props.img;
} else {
  image.value = defaultLogo;
  watchEffect(async () => {
    if (authStore.isAuthenticated) {
      const user = await authStore.getUser();
      if (user && user.tenant && user.tenant.id) {
        const tenantId = user.tenant.id;

        const tenantConfig: TenantConfig = (await new TenantConfigApi().getConfig(tenantId)).data;
        if (tenantConfig.logo) {
          const logo = tenantConfig.logo;
          image.value = logo;
        }
      }
    }
  });
}
</script>

<template>
  <div class="topbar">
    <div class="grid grid-flow-col items-center panel shadow-none" :class="loggedIn ? 'bg-gray-fa' : 'bg-white'">
      <div class="justify-self-start">
        <router-link to="/" class="p-component">
          <img :src="image" alt="Home" class="w-40" />
        </router-link>
      </div>

      <div class="justify-self-end">
        <div v-if="loggedIn" class="profile-actions">
          <router-link v-slot="{ isActive }" :to="{ name: 'appointments' }" class="inline-block mr-4">
            <i class="pi pi-calendar text-3xl hover:text-first" :class="isActive ? 'text-first' : 'text-black'"></i>
          </router-link>
          <router-link
            v-if="Roles[role] === Roles.admin || Roles[role] === Roles.superAdmin"
            v-slot="{ isActive }"
            :to="{ name: 'Admin' }"
            class="inline-block mr-4"
          >
            <i class="pi pi-cog text-3xl hover:text-first" :class="isActive ? 'text-first' : 'text-black'"></i>
          </router-link>
          <router-link v-slot="{ isActive }" :to="{ name: 'Profile' }" class="inline-block mr-4">
            <i class="pi pi-user text-3xl hover:text-first" :class="isActive ? 'text-first' : 'text-black'"></i>
          </router-link>
          <div class="inline-block mr-4" @click.prevent="handleLogout">
            <i class="pi pi-sign-out text-3xl hover:text-first"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
