<script lang="ts" setup>
import { computed, onMounted, reactive, ref, watch, watchEffect } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useSnippet } from '@/store/snippet';
import { Roles } from 'types/roles';
import ToggleButton from 'primevue/togglebutton';

import useMessages from '@/composables/messages';
import { MessageType } from 'types/message';

import PageHeading from '@/components/PageHeading/PageHeading.vue';
import BaseModal from '@/components/BaseModal/BaseModal.vue';
import Dropdown from 'primevue/dropdown';
import HTMLEditor from '@/components/modules/editor.vue';
import Editor from 'primevue/editor';
import Button from 'primevue/button';
import { useTenantStore } from '@/store/tenant';
import { useAuth } from '@/store/auth';
import SnippetApi from '@/services/SnippetApi';

const route = useRoute();
const router = useRouter();
const { addToast } = useMessages();

const openModal = ref(false);
const dataModalDelete = ref({
  id: '',
  name: '',
});

const snippetStore = useSnippet();
const tenantStore = useTenantStore();
const authStore = useAuth();
const role = computed(() => authStore.user?.role);
const tenantId = computed(() => authStore.user?.tenant?.id);
const variables = ref<Array<string>>([]);
snippetStore.getAll();

snippetStore.getAll();
snippetStore.getDefaults();
tenantStore.getAll();
const defaults = computed(() => snippetStore.defaults);
const snippets = computed(() => snippetStore.listAll);
const tenants = computed(() => tenantStore.listAllTenants);

const pageName = computed(() => route.name);
const routeAction = computed(() => route.meta.action);
const formData = reactive<{ id?: string; snippet?: string; value?: string; tenantId?: string }>({
  tenantId: tenantId.value,
});

const toggleEditor = ref(false);

onMounted(async () => {
  if (route.meta.action === 'edit' && route.params) {
    const snippet = snippets.value.find((snippet) => snippet.id === route.params.id);
    if (snippet) {
      formData.id = snippet.id;
      formData.snippet = snippet.snippet;
      formData.tenantId = snippet.tenant.id;
      formData.value = snippet.value;
      variables.value = (await new SnippetApi().getVars(formData.snippet)).data;
    }
  }
});

const isDisabledBtn = computed(() => {
  if (routeAction.value === 'edit') {
    return !formData.snippet || !formData.tenantId || !formData.value;
  }
  return !formData.snippet || !formData.tenantId || !formData.value;
});

async function handleSubmit() {
  if (!formData.snippet || !formData.tenantId || !formData.value) return;

  const body = {
    snippetId: formData.snippet,
    tenantId: formData.tenantId,
    value: formData.value,
  };
  const action = 'erstellen';
  const isSuccess = await snippetStore.create(body);

  if (isSuccess) {
    addToast({
      type: MessageType.Success,
      content: `Textbaustein ${action} erfolgreich`,
    });

    router.push('/admin/snippet');
  } else {
    addToast({
      type: MessageType.Error,
      content: `Textbaustein ${action} fehlgeschlagen`,
    });
  }
}

async function handleUpdate() {
  if (!formData.snippet || !formData.tenantId || !formData.value || !formData.id) return;

  const body = {
    snippetId: formData.snippet,
    tenantId: formData.tenantId,
    value: formData.value,
  };

  const action = 'ändern';
  const isSuccess = await snippetStore.update(formData.id, body);

  if (isSuccess) {
    addToast({
      type: MessageType.Success,
      content: `Textbaustein ${action} erfolgreich`,
    });

    router.push('/admin/snippet');
  } else {
    addToast({
      type: MessageType.Error,
      content: `Textbaustein ${action} fehlgeschlagen`,
    });
  }
}

async function onChangeSnippet() {
  if (formData.snippet) {
    formData.value = defaults.value[formData.snippet];
    variables.value = (await new SnippetApi().getVars(formData.snippet)).data;
  } else {
    formData.value = '';
    variables.value = [];
  }
}

function onClickDelete(data: { id: string; name: string }) {
  dataModalDelete.value = { ...data };
  openModal.value = true;
}

async function onHandleDelete() {
  if (!dataModalDelete.value.id) return;
  await snippetStore.delete(dataModalDelete.value.id);
  dataModalDelete.value = { id: '', name: '' };
  openModal.value = false;
  router.push('/admin/snippet');
}
</script>

<template>
  <div class="page-content w-full branch-add pb-14">
    <PageHeading class="mb-5" :title="pageName" />
    <div class="container-wrapper">
      <div class="grid grid-cols-1 md:grid-cols-2 px-4 md:px-8 mt-14 md:mt-6">
        <div class="field">
          <div class="text-l text-black mb-4 font-semibold">Name</div>
          <div class="mb-6">Wählen Sie den Textbaustein, den Sie überschreiben möchten.</div>
          <div class="grid gap-x-4 gap-y-6 grid-cols-1 md:grid-cols-2">
            <Dropdown
              v-model="formData.snippet"
              :options="Object.keys(defaults)"
              filter
              placeholder="Defaults"
              class="w-full md:w-48 text-s placeholder:text-light-grey p-2"
              :disabled="route.meta.action === 'edit'"
              @change="onChangeSnippet"
            />
          </div>
        </div>
      </div>
      <div v-if="Roles[role] === Roles.superAdmin" class="grid grid-cols-1 md:grid-cols-2 px-4 md:px-8 mt-6 md:mt-14">
        <div class="field">
          <div class="text-l text-black mb-4 font-semibold">Tenant</div>
          <div class="mb-6">Anpassungen gelten für Tenant</div>
          <div class="grid gap-x-4 gap-y-6 grid-cols-1 md:grid-cols-2">
            <Dropdown
              v-model="formData.tenantId"
              :options="tenants"
              option-label="name"
              option-value="id"
              filter
              placeholder="Tenants"
              class="w-full md:w-48 text-s placeholder:text-light-grey p-2"
            />
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 px-4 md:px-8 mt-6 md:mt-14 gap-x-8">
        <div class="field">
          <div class="text-l text-black mb-4 font-semibold">Value</div>
          <div class="mb-6">
            Die Templates können in der Handlebar Syntax bearbeitete werden:
            <a href="https://handlebarsjs.com/guide/" target="_blank">Handlebars Docs</a>
          </div>

          <HTMLEditor
            v-if="toggleEditor"
            v-model="formData.value"
            class="w-full text-s placeholder:text-light-grey p-2"
          ></HTMLEditor>
          <Editor v-else v-model="formData.value" class="w-full text-s placeholder:text-light-grey p-2"></Editor>
          <ToggleButton
            v-model="toggleEditor"
            on-icon="pi pi-code"
            off-icon="pi pi-code"
            on-label="Text"
            off-label="Code"
            class="mt-4"
            :class="{ 'bg-first': toggleEditor, 'bg-dark-grey': !toggleEditor }"
          />
          <div class="mt-4 font-semibold">Preview</div>
          <div v-html="formData.value"></div>
        </div>
        <div class="field explaination">
          <div class="mt-4 font-semibold">Mögliche Variablen</div>
          <ul>
            <li v-for="variable in variables" :key="variable">
              {{ '{' }}{{ '{' }}<span>{{ variable }}</span
              >{{ '}' }}{{ '}' }}
            </li>
          </ul>
        </div>
      </div>
      <div class="mt-6 md:mt-14 px-4 md:px-8 flex items-center">
        <i
          v-if="routeAction === 'edit'"
          class="pi pi-trash text-l cursor-pointer text-red hover:text-red p-2 mr-2 md:mr-4"
          @click="onClickDelete({ id: String(formData.id), name: formData.snippet })"
        ></i>
        <Button
          label="Abbrechen"
          class="p-button-outlined p-button-secondary mr-2 text-s text-black"
          @click="router.go(-1)"
        />
        <Button
          v-if="routeAction === 'edit'"
          :disabled="isDisabledBtn"
          label="Speichern"
          class="p-button-primary bg-first hover:!bg-first border-none text-s"
          @click="handleUpdate"
        />
        <Button
          v-else
          :disabled="isDisabledBtn"
          label="Erstellen"
          class="p-button-primary bg-first hover:!bg-first border-none text-s"
          @click="handleSubmit"
        />
      </div>
    </div>
    <teleport to="body">
      <BaseModal
        :data-modal="dataModalDelete"
        :open="openModal"
        @update:open="openModal = $event"
        @submit="onHandleDelete"
      >
        <template #body="{ slotProps }">
          <div class="flex items-center">
            <i class="pi pi-exclamation-triangle mr-3 text-red text-xl" />
            <span>
              Möchten Sie die Filiale
              <strong>{{ slotProps.name }}</strong>
              wirklich löschen?
            </span>
          </div>
        </template>
      </BaseModal>
    </teleport>
  </div>
</template>

<style></style>
