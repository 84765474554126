<script lang="ts" setup>
import { defineProps, computed, defineEmits } from 'vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';

const props = defineProps<{
  open: boolean;
  dataModal?: object;
  confirm?: string;
  exit?: string;
  hideExit?: boolean;
  header?: string;
}>();

// Emits
const emit = defineEmits(['update:open', 'submit']);

const confirm = props.confirm ? props.confirm : "Ja";
const exit = props.exit ? props.exit : "Nein";
const header = props.header ? props.header : "Bestätigen";

const open = computed({
  get() {
    return props.open;
  },
  set(value: boolean) {
    emit('update:open', value);
  },
});
const onClickSubmit = () => {
  emit('submit');
};
</script>

<template>
  <Dialog
    v-model:visible="open"
    class="base-modal"
    :header="header"
    :style="{ width: '450px' }"
    :modal="true"
  >
    <div class="confirmation-content">
      <slot name="body" :slot-props="dataModal"></slot>
    </div>
    <template #footer>
      <Button
        v-if="!hideExit"
        :label="exit"
        icon="pi pi-times"
        class="p-button-text"
        @click="open = false"
      />
      <Button
        :label="confirm"
        icon="pi pi-check text-first"
        autofocus
        class="p-button-text"
        @click="onClickSubmit"
      />
    </template>
  </Dialog>
</template>
<style scoped>
.base-modal .p-button:focus {
  @apply shadow-none;
}
</style>
