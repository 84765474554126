<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useSnippet } from '@/store/snippet';
import useMessages from '@/composables/messages';
import { Roles } from 'types/roles';

import PageHeading from '@/components/PageHeading/PageHeading.vue';
import BaseModal from '@/components/BaseModal/BaseModal.vue';
import NoData from '@/components/NoData/NoData.vue';

import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import { useAuth } from '@/store/auth';

const route = useRoute();
const router = useRouter();
const snippetStore = useSnippet();
const authStore = useAuth();

const pageName = computed(() => route.name);
const role = computed(() => authStore.user?.role);

const loading = ref(false);
const openModal = ref(false);
const dataModalDelete = ref({
  id: '',
  name: '',
});
async function refresh() {
  loading.value = true;
  await snippetStore.getAll();
  loading.value = false;
}

const onClickDelete = (data: { id: string; name: string }) => {
  dataModalDelete.value = data;
  openModal.value = true;
};

const onHandleDelete = async () => {
  if (!dataModalDelete.value.id) return;
  await snippetStore.delete(dataModalDelete.value.id);
  dataModalDelete.value = { id: '', name: '' };
  openModal.value = false;
  snippetStore.getAll();
};

snippetStore.getAll();
const snippets = computed(() => snippetStore.listAll);
</script>

<template>
  <div class="page-content w-full">
    <PageHeading
      class="mb-5"
      :title="String(pageName)"
      :button="true"
      to="/admin/snippet/add"
      :refresh="true"
      :loading="loading"
      @click-refresh="refresh"
    />
    <div class="card">
      <DataTable :value="snippets" responsive-layout="scroll" class="custom-table" :loading="loading">
        <Column field="snippet" header="Name" :sortable="true" sort-field="snippet">
          <template #body="slotProps">
            <span>{{ slotProps.data.snippet }}</span>
          </template>
        </Column>
        <Column
          v-if="Roles[role] === Roles.superAdmin"
          field="tenant"
          header="Tenant"
          :sortable="true"
          sort-field="tenant"
        >
          <template #body="slotProps">
            <span>{{ slotProps.data.tenant.name }}</span>
          </template>
        </Column>
        <Column field="active" header>
          <template #body="slotProps">
            <i
              class="pi pi-cog text-l cursor-pointer hover:text-first p-2 mr-2"
              :slotProps="slotProps"
              @click="router.push({ path: `/admin/snippet/edit/${slotProps.data.id}` })"
            ></i>
            <i
              class="pi pi-trash text-l cursor-pointer text-red hover:text-red p-2"
              :slotProps="slotProps"
              @click="onClickDelete({ id: slotProps.data.id, name: slotProps.data.snippet })"
            ></i>
          </template>
        </Column>
        <template #empty>
          <div class="flex items-center justify-center py-2 md:py-10">
            <NoData
              :title="`Keine Textbausteine angelegt!`"
              :loading="loading"
              description="Legen Sie bitte oben eine neue Textbausteine an."
            />
          </div>
        </template>
      </DataTable>
    </div>
  </div>
  <teleport to="body">
    <BaseModal
      :data-modal="dataModalDelete"
      :open="openModal"
      @update:open="openModal = $event"
      @submit="onHandleDelete"
    >
      <template #body="{ slotProps }">
        <div class="flex items-center">
          <i class="pi pi-exclamation-triangle mr-3 text-red text-xl" />
          <span>
            Möchten Sie den Textbaustein
            <strong>{{ slotProps.name }}</strong>
            wirklich löschen?
          </span>
        </div>
      </template>
    </BaseModal>
  </teleport>
</template>
