import { createApp } from 'vue';
import App from './App.vue';
import Vuelidate from 'vuelidate';
import PrimeVue from 'primevue/config';
import axios from './plugins/axios';
import VueAxios from 'vue-axios';
import { createPinia } from 'pinia';
import router from '~/router/index';
import ToolTip from 'primevue/tooltip';

// Prime toast
import ToastService from 'primevue/toastservice';

const app = createApp(App);

app.use(PrimeVue);
app.directive('tooltip', ToolTip);
app.use(VueAxios, axios);
app.use(createPinia());
app.use(router);
app.use(ToastService);
app.use(Vuelidate);

app.mount('#app');
