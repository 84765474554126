import BaseApi from './BaseApi';
import { BASE_URL } from 'types/config';
import { IInbound } from 'types/inbound';
import { AxiosPromise } from 'axios';

export default class InboundApi extends BaseApi {
  private baseURL = `${BASE_URL}/inbound`;
  constructor() {
    super();
  }

  public getAll(): AxiosPromise<IInbound[]> {
    return this.get(this.baseURL);
  }

  public create(body: AxiosPromise<IInbound>) {
    return this.post(this.baseURL, body);
  }

  public detail(id: string): AxiosPromise<IInbound> {
    return this.get(`${this.baseURL}/${id}`);
  }

  public remove(id: string): AxiosPromise<void> {
    return this.delete(`${this.baseURL}/${id}`);
  }

  public update(id: string, body: IInbound): AxiosPromise<IInbound> {
    return this.patch(`${this.baseURL}/${id}`, body);
  }
}
