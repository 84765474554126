import { defineStore } from 'pinia';
import InboundApi from '@/services/InboundApi';
import { IInbound } from 'types/inbound';

const inboundApi = new InboundApi();
export const useInbound = defineStore('admin-inbound', {
  state: () => {
    return {
      listAll: <IInbound[]>[],
    };
  },
  actions: {
    async getAll(): Promise<IInbound[]> {
      try {
        const { data } = await inboundApi.getAll();
        this.listAll = data;
        return data;
      } catch (e) {
        return e;
      }
    },
    async create(body: IInbound) {
      try {
        await inboundApi.create(body);
        return true;
      } catch (e) {
        return false;
      }
    },
    async getDetail(id: string): Promise<IInbound> {
      try {
        const { data } = await inboundApi.detail(id);
        return data;
      } catch (e) {
        return e;
      }
    },
    async update(id: string, body: IInbound) {
      try {
        await inboundApi.update(id, body);
        return true;
      } catch (e) {
        return false;
      }
    },
    async delete(id: string) {
      try {
        const { data } = await inboundApi.remove(id);
        return data;
      } catch (e) {
        return e;
      }
    },
  },
});
