<script lang="ts" setup>
import { defineProps } from 'vue';
import { useRouter } from 'vue-router';
const router = useRouter();

const props = defineProps<{
  title: string;
  button?: boolean;
  to?: string;
  refresh?: boolean;
  loading?: boolean;
}>();

const emit = defineEmits<{
  (e: 'clickRefresh'): void
}>();

const navigate = () => {
  router.push({ path: props.to });
};
</script>
<template>
  <h3 class="font-semibold p-8 flex items-center justify-between">
    {{ title }}
    <div class="flex">
      <div v-if="refresh" class="mr-2">
        <i v-if="loading" class="pi pi-spin pi-spinner button-refresh"></i>
        <i v-else class="pi pi-refresh button-refresh" @click="emit('clickRefresh')"></i>
      </div>

      <div>
        <a
          v-if="button && to"
          class="button-add text-white inline-block bg-first rounded flex items-center justify-center"
          :class="{ 'cursor-pointer': button && to }"
          @click="navigate"
        >
          <i class="pi pi-plus text-white text-xl"></i>
        </a>
      </div>
    </div>
  </h3>
</template>
<style scoped>
.button-add {
  padding: 5px 7px;
}

.button-refresh {
  font-size: 30px;
}
</style>
