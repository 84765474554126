<script lang="ts" setup>
import { computed, reactive, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dropdown, { DropdownChangeEvent } from 'primevue/dropdown';
import MultiSelect from 'primevue/multiselect';

import { useUserStore } from '@/store/user';
import { useTenantStore } from '@/store/tenant';
import { useInbound } from '@/store/inbound';

import { Roles } from 'types/roles';
import { IInbound } from 'types/inbound';
import { User, UserAgent, UserCreate } from 'types/user';

import PageHeading from '@/components/PageHeading/PageHeading.vue';
import BaseModal from '@/components/BaseModal/BaseModal.vue';

import useMessages from '@/composables/messages';
import { MessageType } from 'types/message';

import { email } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { ITenant } from 'types/tenant';
import { useAuth } from '@/store/auth';

import UserApi from '@/services/UserApi';

const { addToast } = useMessages();

const authStore = useAuth();

await authStore.getUser();
const role = computed(() => authStore.user.role);

const roles = ref(
  Object.entries(Roles).map(([key, value]) => {
    return {
      label: value,
      value: key,
    };
  }),
);

if (Roles[role.value] === Roles.admin) {
  roles.value = roles.value.filter((r) => Roles[r.value] !== Roles.superAdmin);
}

const userStore = useUserStore();
const tenantStore = useTenantStore();

const userAgents = await userStore.getUserAgents();
const userAgentsDropdownData = ref(
  userAgents.map((agent: UserAgent) => {
    return {
      label: `${agent.id} - ${agent.name}`,
      value: agent.id,
    };
  }),
);

const route = useRoute();
const router = useRouter();
const pageName = String(route.name);
const routeAction = route?.meta.action;
const userId: string | string[] = route?.params?.id;

const formData = ref<User>({
  email: '',
  firstName: '',
  lastName: '',
  // department: '',
  id: '',
  role: undefined,
  inbounds: [],
  inboundIds: [],
  agentId: undefined,
  tenant: undefined,
});

const validations = { formData: { email: { email } } };
const $v = useVuelidate(validations.formData, formData);

const inboundStore = useInbound();
const inboundChannels = ref(
  (await inboundStore.getAll()).map((inboundChannel: IInbound) => {
    return {
      label: inboundChannel.name,
      value: inboundChannel.id,
    };
  }),
);

const submitMode = routeAction == 'edit' ? 'Speichern' : 'Erstellen';

const openModal = ref(false);
const dataModalDelete = ref({
  id: '',
  firstName: '',
  lastName: '',
});

let selectedTenant = [] as unknown as ITenant[];
if (routeAction === String('edit')) {
  const user: User = await userStore.getUserDetail(String(userId));
  const userInboundIds = user.inbounds.map((i: IInbound) => i.id);
  formData.value = {
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    // department: user.department,
    id: user.id,
    role: user.role,
    inbounds: user.inbounds,
    inboundIds: userInboundIds,
    agentId: user.agentId,
    tenant: user.tenant,
  };
  if (user.tenant) selectedTenant = [user.tenant];
}

const handleSubmit = async () => {
  $v.value.$validate();

  let isSuccess: boolean;
  let action: string;

  const userCreate: UserCreate = {
    email: formData.value.email,
    firstName: formData.value.firstName,
    lastName: formData.value.lastName,
    role: formData.value.role,
    inbounds: formData.value.inboundIds,
    agentId: formData.value.agentId,
    tenant: formData.value.tenant?.id,
  };

  if (routeAction === 'add' && !userId) {
    isSuccess = await userStore.createUser(userCreate);
    action = 'erstellen';
  } else {
    isSuccess = await userStore.updateUser(String(userId), userCreate);
    action = 'ändern';
  }

  if (isSuccess) {
    addToast({
      type: MessageType.Success,
      content: `Benutzer ${action} erfolgreich`,
    });

    router.push('/admin/user');
  } else {
    addToast({
      type: MessageType.Error,
      content: `Benutzer ${action} fehlgeschlagen`,
    });
  }
};

const onClickDelete = (data: { id: string; firstName: string; lastName: string }) => {
  dataModalDelete.value = { ...data };
  openModal.value = true;
};

const onHandleDelete = async () => {
  if (!dataModalDelete.value.id) return;
  const isSuccess: boolean = await userStore.deleteUser(dataModalDelete.value.id);
  dataModalDelete.value = { id: '', firstName: '', lastName: '' };
  openModal.value = false;

  if (isSuccess) {
    addToast({
      type: MessageType.Success,
      content: `Benutzer löschen erfolgreich`,
    });
    router.push('/admin/user');
  } else {
    addToast({
      type: MessageType.Error,
      content: `Benutzer löschen fehlgeschlagen`,
    });
  }
};

const onRoleDropdownChange = (event: DropdownChangeEvent) => {
  formData.value.role = event.value;
};

const onTenantDropdownChange = (event: DropdownChangeEvent) => {
  formData.value.tenant = event.value;
};

const onAgentDropdownChange = (event: DropdownChangeEvent) => {
  formData.value.agentId = event.value;
};

/**
 * Handle tenant dropdown
 */
/**
 *
 * @param key of ITenantOptions : string
 * @returns {ITenantOptions<T>}
 */
const tenants = computed(() => {
  return tenantStore.listAllTenants;
});
/**
 *
 * @param key of ITenerantOptions : String
 * set loading indecator for specific dropdown by key
 * loads the options if not loaded
 */
async function loadTenants() {
  // if options for key already exist stop loading
  if (tenants.value.length) return;
  loading.tenantDropdown = true;
  loaded.tenantDropdown = false;
  await tenantStore.getAll();
  loading.tenantDropdown = false;
  loaded.tenantDropdown = true;
}
// reactive loading object for each dropdown
const loading = reactive({
  tenantDropdown: false,
});
const loaded = reactive({
  tenantDropdown: tenants.value.length as unknown as boolean,
});

const isDisabledBtn = computed(() => !formData.value.firstName || !formData.value.lastName);

const userApi = new UserApi();
const resetPassword = async () => {
  const isSuccess = await userApi.resetPassword((formData.value.id ||= ''));
  if (isSuccess) {
    addToast({
      type: MessageType.Success,
      content: `Passwort zurücksetzen erfolgreich`,
    });
  } else {
    addToast({
      type: MessageType.Error,
      content: `Passwort zurücksetzen fehlgeschlagen`,
    });
  }
};
</script>

<template>
  <div class="page-content w-full user-add">
    <PageHeading class="mb-5" :title="pageName" />
    <div class="container-wrapper">
      <div class="grid grid-cols-1 md:grid-cols-2 px-4 md:px-8 mt-14 md:mt-6">
        <div class="field">
          <div class="text-l text-black mb-4 font-semibold">Name</div>
          <div class="mb-6">Wählen Sie den Namen des Benutzers aus.</div>
          <div class="grid gap-x-6 md:grid-cols-4 md:grid-rows-1 grid-cols-1 grid-rows-2">
            <div>
              <InputText
                v-model="formData.firstName"
                placeholder="Vorname"
                class="w-full h-9 text-s placeholder:text-light-grey p-2"
              />
            </div>
            <div>
              <InputText
                v-model="formData.lastName"
                placeholder="Nachname"
                class="w-full h-9 text-s placeholder:text-light-grey p-2"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 px-4 md:px-8 mt-6 md:mt-14">
        <div class="field">
          <div class="text-l text-black mb-4 font-semibold">E-Mail</div>
          <div class="mb-6">Wählen Sie die E-Mail Adresse des Benutzers aus.</div>
          <div class="grid gap-x-4 gap-y-6 grid-cols-1 md:grid-cols-2">
            <div class="grid grid-cols-1">
              <div :class="{ 'validation-error': $v['email'].$error }">
                <InputText
                  v-model="formData.email"
                  placeholder="E-Mail"
                  type="email"
                  class="w-full h-9 text-s placeholder:text-light-grey p-2"
                />
              </div>
              <div :style="{ visibility: $v['email'].$dirty && $v['email'].$invalid ? 'visible' : 'hidden' }">
                <div class="error ml-1 mt-0.5">Keine gültige E-Mail Adresse</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="grid grid-cols-1 md:grid-cols-2 px-4 md:px-8 mt-6 md:mt-14">
        <div class="field">
          <div class="text-l text-black mb-4 font-semibold">Abteilung</div>
          <div class="mb-6">Für welche Abteilung ist der Benutzer zuständig?</div>
          <div class="grid gap-x-4 gap-y-6 grid-cols-1 md:grid-cols-2">
            <InputText
              v-model="formData.department"
              placeholder="Abteilung"
              class="w-full h-9 text-s placeholder:text-light-grey p-2"
            />
          </div>
        </div>
      </div>-->
      <div class="grid grid-cols-1 md:grid-cols-2 px-4 md:px-8 mt-6 md:mt-14">
        <div class="field">
          <div class="text-l text-black mb-4 font-semibold">Rolle</div>
          <div class="mb-6">Wählen Sie die Rolle des neuen Benutzers aus.</div>
          <div class="grid gap-x-4 gap-y-6 grid-cols-1 md:grid-cols-2">
            <Dropdown
              id="role"
              v-model="formData.role"
              :options="roles"
              option-label="label"
              option-value="value"
              class="w-full"
              @change="onRoleDropdownChange"
            ></Dropdown>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 px-4 md:px-8 mt-6 md:mt-14">
        <div class="field">
          <div class="text-l text-black mb-4 font-semibold">Nutzerlizenz</div>
          <div class="mb-6">Wählen Sie die Nutzerlizenz aus die verwendet werden soll.</div>
          <div class="grid gap-x-4 gap-y-6 grid-cols-1 md:grid-cols-2">
            <Dropdown
              id="role"
              v-model="formData.agentId"
              :options="[{ label: 'Keine Lizenz ausgwählt', value: 0 }].concat(userAgentsDropdownData)"
              option-label="label"
              option-value="value"
              :filter="true"
              filter-placeholder="Suche Nutzerlizenz"
              placeholder="Nutzerlizenz"
              class="w-full"
              @change="onAgentDropdownChange"
            ></Dropdown>
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 px-4 md:px-8 mt-6 md:mt-14">
        <div class="field">
          <div class="text-l text-black mb-4 font-semibold">Abonnierte Kalender</div>
          <div class="mb-6">Wählen Sie alle Kalender, dessen Termine der Benutzer sehen soll, aus.</div>
          <div class="grid gap-x-4 gap-y-6 grid-cols-1 md:grid-cols-2">
            <MultiSelect
              v-model="formData.inboundIds"
              :options="inboundChannels"
              :filter="true"
              option-label="label"
              option-value="value"
              placeholder="Kalender"
            />
          </div>
        </div>
      </div>
      <div
        v-if="routeAction === 'edit' && (Roles[role] === Roles.admin || Roles[role] === Roles.superAdmin)"
        class="grid grid-cols-1 md:grid-cols-2 px-4 md:px-8 mt-6 md:mt-14"
      >
        <div class="field">
          <div class="text-l text-black mb-4 font-semibold">Passwort zurücksetzen</div>
          <div class="mb-6">Setzen Sie das Passwort des Benutzers zurück.</div>
          <div class="grid gap-x-4 gap-y-6 grid-cols-1 md:grid-cols-2">
            <Button
              class="p-button-primary bg-first hover:!bg-first border-none text-s"
              label="Zurücksetzen"
              @click="resetPassword()"
            />
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 px-4 md:px-8 mt-6 md:mt-14">
        <div class="field">
          <div class="text-l text-black mb-4 font-semibold">Tenant</div>
          <div v-if="Roles[role] === Roles.superAdmin" class="mb-6">
            Wählen Sie den Tenant aus dem der Benutzer zugewiesen werden soll.
          </div>
          <div class="grid gap-x-4 gap-y-6 grid-cols-1 md:grid-cols-2">
            <div v-if="Roles[role] === Roles.superAdmin">
              <Dropdown
                id="tenant"
                v-model="formData.tenant"
                option-label="name"
                class="w-full"
                empty-message="Keine Tenants verfügbar"
                :options="!loaded.tenantDropdown ? selectedTenant : tenants"
                :loading="loading.tenantDropdown"
                :placeholder="'Tenant wählen'"
                @click="loadTenants"
                @change="onTenantDropdownChange"
              ></Dropdown>
            </div>
            <div v-else>
              <div v-if="formData.tenant">
                Der zugewiesene Tenant ist
                <strong>{{ formData.tenant.name }}</strong>
              </div>
              <div v-else>Kein Tenant zugewiesen.</div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-6 mb-2 md:mt-14 px-4 md:px-8 flex items-center">
        <i
          v-if="routeAction === 'edit'"
          class="pi pi-trash text-l cursor-pointer text-red hover:text-red p-2 mr-2 md:mr-4"
          @click="onClickDelete({ id: String(userId), firstName: formData.firstName, lastName: formData.lastName })"
        ></i>
        <Button
          label="Abbrechen"
          class="p-button-outlined p-button-secondary mr-2 text-s text-black"
          @click="router.go(-1)"
        />
        <Button
          :disabled="isDisabledBtn"
          class="p-button-primary bg-first hover:!bg-first border-none text-s"
          @click="handleSubmit"
          >{{ submitMode }}</Button
        >
      </div>

      <teleport to="body">
        <BaseModal
          :data-modal="dataModalDelete"
          :open="openModal"
          @update:open="openModal = $event"
          @submit="onHandleDelete"
        >
          <template #body="slotProps">
            <div class="flex items-center">
              <i class="pi pi-exclamation-triangle mr-3 text-red text-xl" />
              <span>
                Möchten Sie den Benutzer
                <strong>{{ slotProps.slotProps.firstName }} {{ slotProps.slotProps.lastName }}</strong>
                wirklich löschen?
              </span>
            </div>
          </template>
        </BaseModal>
      </teleport>
    </div>
  </div>
</template>
<style>
.validation-error {
  border: 1px solid red;
  border-radius: 0.375rem;
}

.error {
  color: red;
  font-size: smaller;
}
</style>
