import BaseApi from './BaseApi';
import { BASE_URL } from 'types/config';
import { AxiosPromise } from 'axios';
import { User, UserOptions } from 'types/user';

export default class UserApi extends BaseApi {
  private baseURL = `${BASE_URL}/user`;
  constructor() {
    super();
  }

  public getAll(): AxiosPromise<User[]> {
    return this.get(`${this.baseURL}`);
  }

  public getAgents(): AxiosPromise<UserOptions> {
    return this.get(`${this.baseURL}/options`);
  }

  public create(body: AxiosPromise<User>) {
    return this.post(`${this.baseURL}`, body);
  }

  public detail(id: string): AxiosPromise<User> {
    return this.get(`${this.baseURL}/${id}`);
  }

  public remove(id: string): AxiosPromise<void> {
    return this.delete(`${this.baseURL}/${id}`);
  }

  public update(id: string, body: object = {}): AxiosPromise<User> {
    return this.patch(`${this.baseURL}/${id}`, body);
  }

  public async resetPassword(id: string): Promise<boolean> {
    try { await this.get(`${this.baseURL}/${id}/forgotPassword`); return true } catch (e) { return false; }
  }

  public async setPassword(id: string, body: { password: string }): Promise<boolean> {
    try { await this.patch(`${this.baseURL}/${id}/setPassword`, body); return true } catch (e) { return false; }
  }
}
