import BaseApi from "./BaseApi";
import { BASE_URL } from "types/config";
import type { AxiosPromise } from "axios";
import type TenantConfig from "types/tenant-config";

export default class TenantConfigApi extends BaseApi {
  private baseURL: string = BASE_URL;
  constructor() {
    super();
  }

  public getConfig(tenantId: string): AxiosPromise<TenantConfig> {
    return this.getPlain(
      `${this.baseURL}/tenant/config/${tenantId}`
    );
  }
}
