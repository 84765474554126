<script lang="ts" setup>
import { ref, reactive, computed, watch } from 'vue';
import axios from '~/plugins/axios';
import { BASE_URL } from 'types/config';

// Prime Components
import Card from 'primevue/card';
import InputText from 'primevue/inputtext';
import PrimeButton from 'primevue/button';

// custom components
import BaseLogo from '@/components/BaseLogo/BaseLogo.vue';
import BaseModal from '@/components/BaseModal/BaseModal.vue';

import { useAuth } from '@/store/auth';
import { useRouter } from 'vue-router';

const authStore = useAuth();
const router = useRouter();

// Login Modal controles
const formData = reactive({
  username: '',
  password: '',
});

const isLoginFailed = ref(false);

watch(formData, () => {
  if (isLoginFailed.value) {
    isLoginFailed.value = false;
  }
});

const handleSubmit = async () => {
  try {
    const { data } = await axios.post(`${BASE_URL}/auth/login`, formData);
    authStore.setDataLogin(data);
    router.push('/appointments');
  } catch (e) {
    isLoginFailed.value = true;
    throw new Error(e);
  }
};

const submitLabel = 'Login';

const openModal = ref(false);
const showForgotPasswordModal = () => {
  openModal.value = true;
};

const exitForgotPasswordModal = () => {
  openModal.value = false;
};

const disabledBtn = computed(() => !formData.username || !formData.password);
</script>
<template>
  <div class="login-page">
    <div class="fixed h-full w-full flex items-center flex-col justify-center bg-gray-fa">
      <Card class="card-login bg-white p-8">
        <template #title>
          <div class="text-xl font-normal text-black my-4 px-4">WebConsult</div>
        </template>
        <template #content>
          <div class="p-fluid formgrid grid">
            <div class="field col-12 md:col-4 mt-3">
              <label for="email" class="inline-block mb-3">{{ submitLabel }}</label>
              <InputText
                id="email"
                v-model="formData.username"
                placeholder="E-Mail"
                type="email"
                :class="{ 'p-invalid': isLoginFailed }"
              />
            </div>
            <div class="field col-12 md:col-4 mt-3">
              <InputText
                id="password"
                v-model="formData.password"
                placeholder="Passwort"
                type="password"
                :class="{ 'p-invalid': isLoginFailed }"
              />
            </div>
            <small v-if="isLoginFailed" class="p-error mt-2">The E-Mail or Password is Incorrect</small>
          </div>
        </template>

        <template #footer>
          <div class="flex justify-between">
            <i @click="showForgotPasswordModal()">Passwort vergessen</i>
            <div>
              <PrimeButton
                :label="submitLabel"
                class="button-login"
                badge-class="text-white"
                :disabled="disabledBtn || isLoginFailed"
                @click.prevent="handleSubmit"
              ></PrimeButton>
            </div>
          </div>
        </template>
      </Card>
      <BaseLogo class="mb-20 mt-2.5" />
    </div>
    <teleport to="body">
      <BaseModal
        :header="'Zurücksetzen'"
        :open="openModal"
        :confirm="'OK'"
        :hide-exit="true"
        @update:open="openModal = $event"
        @submit="exitForgotPasswordModal"
      >
        <template #body="{}">
          <div class="flex items-center">
            <i class="pi pi-exclamation-triangle mr-3 text-red text-xl" />
            <span>Zum Zurücksetzen des Passworts wenden Sie sich bitte an einen Administrator.</span>
          </div>
        </template>
      </BaseModal>
    </teleport>
  </div>
</template>
<style>
.login-page .card-login {
  min-width: 373px;
  @apply shadow-none;
}
.login-page .p-card .p-card-body {
  @apply p-0;
}
.login-page .p-card .p-card-content {
  @apply p-0;
}
.login-page .button-login {
  @apply bg-first px-3 py-2 text-s border-transparent;
}

.login-page .button-login:hover {
  @apply bg-first border-transparent;
}
.login-page .button-login .p-button-label {
  @apply text-white;
}
.login-page .p-card-body .p-card-footer {
  @apply pt-3;
}
</style>
