<template>
  <div>
    <codemirror
      v-model="code"
      placeholder="Code goes here..."
      :style="{ height: '400px' }"
      :autofocus="true"
      :indent-with-tab="true"
      :tab-size="2"
      :extensions="[html()]"
    />
  </div>
</template>

<script lang="ts" setup>
import { Codemirror } from 'vue-codemirror';
import { html } from '@codemirror/lang-html';
import { computed } from 'vue';

const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
});

const code = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const emit = defineEmits(['update:modelValue']);
</script>
