import axios, { AxiosRequestConfig, AxiosPromise } from 'axios';

import '~/plugins/axios';
export default class BaseApi {
  protected request(config: AxiosRequestConfig) {
    return axios.request(config);
  }

  protected get<T>(url: string, config?: AxiosRequestConfig): AxiosPromise<T> {
    return axios.get(url, config);
  }

  protected getPlain(url: string) {
    return axios.create().get(url);
  }

  protected delete<T>(url: string, config?: AxiosRequestConfig): AxiosPromise<T> {
    return axios.delete(url, config);
  }

  protected post<T>(url: string, data: object, config?: AxiosRequestConfig): AxiosPromise<T> {
    return axios.post(url, data, config);
  }

  protected put<T>(url: string, data: object, config?: AxiosRequestConfig): AxiosPromise<T> {
    return axios.put(url, data, config);
  }

  protected patch<T>(url: string, data: object, config?: AxiosRequestConfig): AxiosPromise<T> {
    return axios.patch(url, data, config);
  }
}
