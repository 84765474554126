<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import Button from 'primevue/button';
import InputText from 'primevue/inputtext';

import { useLocalBranch } from '@/store/local-branch';
import { ILocalBranch } from 'types/local-branch';

import PageHeading from '@/components/PageHeading/PageHeading.vue';
import BaseModal from '@/components/BaseModal/BaseModal.vue';

import useMessages from '@/composables/messages';
import { MessageType } from 'types/message';

const { addToast } = useMessages();

const localBranchStore = useLocalBranch();

const route = useRoute();
const router = useRouter();
const pageName = route.name;
const routeAction = route?.meta.action;
const localBranchId: string | string[] = route?.params?.id;

const formData = ref<ILocalBranch>({
  name: '',
  street: '',
  number: '',
  zip: '',
  city: '',
});
const openModal = ref(false);
const dataModalDelete = ref({
  id: '',
  name: '',
});

if (routeAction === String('edit')) {
  const localBranch: ILocalBranch = await localBranchStore.getDetail(String(localBranchId));
  formData.value = {
    name: localBranch.name,
    street: localBranch.street,
    number: localBranch.number,
    zip: localBranch.zip,
    city: localBranch.city,
  };
}

const handleSubmit = async () => {
  let isSuccess;
  let action;
  if (routeAction === 'add' && !localBranchId) {
    action = 'erstellen';
    isSuccess = await localBranchStore.create(formData.value);
  } else {
    action = 'ändern';
    isSuccess = await localBranchStore.update(String(localBranchId), formData.value);
  }

  if (isSuccess) {
    addToast({
      type: MessageType.Success,
      content: `Filiale ${action} erfolgreich`,
    });

    router.push('/admin/branch');
  } else {
    addToast({
      type: MessageType.Error,
      content: `Filiale ${action} fehlgeschlagen`,
    });
  }
};

const onClickDelete = (data: { id: string; name: string }) => {
  dataModalDelete.value = { ...data };
  openModal.value = true;
};

const onHandleDelete = async () => {
  if (!dataModalDelete.value.id) return;
  await localBranchStore.delete(dataModalDelete.value.id);
  dataModalDelete.value = { id: '', name: '' };
  openModal.value = false;
  router.push('/admin/branch');
};

const isDisabledBtn = computed(
  () =>
    !formData.value.name ||
    !formData.value.street ||
    !formData.value.number ||
    !formData.value.zip ||
    !formData.value.city,
);
</script>
<template>
  <div class="page-content w-full branch-add">
    <PageHeading class="mb-5" :title="pageName" />
    <div class="container-wrapper">
      <div class="grid grid-cols-1 md:grid-cols-2 px-4 md:px-8 mt-14 md:mt-6">
        <div class="field">
          <div class="text-l text-black mb-4 font-semibold">Name</div>
          <div class="mb-6">Wählen Sie den Namen der Filiale, die Sie hinzufügen möchten.</div>
          <div class="grid gap-x-4 gap-y-6 grid-cols-1 md:grid-cols-2">
            <InputText
              v-model="formData.name"
              placeholder="Name der FIliale"
              class="w-full md:w-48 h-9 text-s placeholder:text-light-grey p-2"
            />
          </div>
        </div>
      </div>
      <div class="grid grid-cols-1 md:grid-cols-2 px-4 md:px-8 mt-6 md:mt-14">
        <div class="field">
          <div class="text-l text-black mb-4 font-semibold">Adresse</div>
          <div class="mb-6">Ort der Filiale</div>
          <div class="grid gap-x-4 gap-y-6 grid-cols-1 md:grid-cols-2">
            <InputText
              v-model="formData.street"
              placeholder="Straße"
              class="w-full h-9 text-s placeholder:text-light-grey p-2"
            />
            <InputText
              v-model="formData.number"
              placeholder="Hausnummer"
              class="w-full h-9 text-s placeholder:text-light-grey"
            />
            <InputText v-model="formData.zip" placeholder="PLZ" class="w-full h-9 text-s placeholder:text-light-grey" />
            <InputText
              v-model="formData.city"
              placeholder="Stadt"
              class="w-full h-9 text-s placeholder:text-light-grey"
            />
          </div>
        </div>
      </div>
      <div class="mt-6 md:mt-14 px-4 md:px-8 flex items-center">
        <i
          v-if="routeAction === 'edit'"
          class="pi pi-trash text-l cursor-pointer text-red hover:text-red p-2 mr-2 md:mr-4"
          @click="onClickDelete({ id: String(localBranchId), name: formData.name })"
        ></i>
        <Button
          label="Abbrechen"
          class="p-button-outlined p-button-secondary mr-2 text-s text-black"
          @click="router.go(-1)"
        />
        <Button
          :disabled="isDisabledBtn"
          :label="routeAction === 'edit' ? 'Ändern' : 'Erstellen'"
          class="p-button-primary bg-first hover:!bg-first border-none text-s"
          @click="handleSubmit"
        />
      </div>
    </div>
    <teleport to="body">
      <BaseModal
        :data-modal="dataModalDelete"
        :open="openModal"
        @update:open="openModal = $event"
        @submit="onHandleDelete"
      >
        <template #body="{ slotProps }">
          <div class="flex items-center">
            <i class="pi pi-exclamation-triangle mr-3 text-red text-xl" />
            <span>
              Möchten Sie die Filiale
              <strong>{{ slotProps.name }}</strong>
              wirklich löschen?
            </span>
          </div>
        </template>
      </BaseModal>
    </teleport>
  </div>
</template>
<style>
.branch-add .p-inputtext:focus {
  @apply !shadow-xs !border-first;
}
.branch-add .p-button .p-button-label {
  @apply text-s font-normal text-white;
}
.branch-add .p-button.p-button-secondary {
  @apply !border-light-grey;
}
.branch-add .p-button.p-button-secondary .p-button-label {
  @apply text-black border-light-grey;
}
</style>
