import { createWebHistory, createRouter } from 'vue-router';
import LoginView from '@/views/login-view.vue';
import Main from '@/components/layouts/Main.vue';

import AdminUserList from '@/views/admin/user/list.vue';
import AdminUserAdd from '@/views/admin/user/add.vue';
import AdminTenantList from '@/views/admin/tenant/list.vue';
import AdminTenantAdd from '@/views/admin/tenant/add.vue';
import AdminView from '@/views/admin/admin-view.vue';

import AdminBranchList from '@/views/admin/branch/list.vue';
import AdminBranchAdd from '@/views/admin/branch/add.vue';

import AppointmentsView from '@/views/appointments/appointments-view.vue';

import AdminInboundList from '@/views/admin/inbound/list.vue';
import AdminInboundAdd from '@/views/admin/inbound/add.vue';

import AdminSnippetList from '@/views/admin/snippet/list.vue';
import AdminSnippetAdd from '@/views/admin/snippet/add.vue';

import Profile from '@/views/profile/profile.vue';
import MyProfile from '@/views/profile/myprofile.vue';
import AccountSetting from '@/views/profile/account-setting.vue';
import EmailSetting from '@/views/profile/email-setting.vue';

import CallbackView from '@/views/callback-view.vue';
import JoinView from '@/views/join-view.vue';

import { authGuard, loginRediectGuard } from '@/router/guard';
import { Roles } from 'types/roles';

/**
 * ceate routes for the application
 */
const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '',
      component: Main,
      name: 'main',
      beforeEnter: loginRediectGuard,
      redirect: { name: 'appointments' },
      children: [
        {
          path: 'appointments',
          component: AppointmentsView,
          name: 'appointments',
          meta: { requiresAuth: true, requiredRole: [Roles.agent, Roles.admin, Roles.superAdmin] },
          children: [
            {
              path: ':id',
              component: AppointmentsView,
              name: 'detail appointments',
              meta: {
                requiresAuth: true,
                requiredRole: Roles.agent,
              },
            },
          ],
        },
        {
          path: 'profile',
          component: Profile,
          name: 'Profile',
          redirect: { name: 'MyProfile' },
          children: [
            {
              path: 'my-profile',
              component: MyProfile,
              name: 'MyProfile',
              alias: 'MyProfile',
            },
            {
              path: 'email-setting',
              component: EmailSetting,
              name: 'EmailSetting',
              alias: 'EmailSetting',
            },
            {
              path: 'account-setting',
              component: AccountSetting,
              name: 'AccountSetting',
              alias: 'AccountSetting',
            },
          ],
        },
        {
          path: 'admin',
          component: AdminView,
          name: 'Admin',
          redirect: { name: 'Inbound' },
          children: [
            {
              path: 'inbound',
              component: AdminInboundList,
              name: 'Inbound',
              alias: 'Inbound',
              meta: {
                requiresAuth: true,
                requiredRole: [Roles.admin, Roles.superAdmin],
              },
            },
            {
              path: 'inbound/add',
              component: AdminInboundAdd,
              name: 'Kalender erstellen',
              meta: {
                active: 'Kalender',
                requiresAuth: true,
                requiredRole: [Roles.admin, Roles.superAdmin],
              },
            },
            {
              path: 'inbound/:id/edit',
              component: AdminInboundAdd,
              name: 'Kalender bearbeiten',
              meta: {
                active: 'Kalender',
                requiresAuth: true,
                requiredRole: [Roles.admin, Roles.superAdmin],
              },
            },
            {
              path: 'user',
              component: AdminUserList,
              name: 'Benutzer',
              alias: 'Benutzer',
              meta: {
                active: 'Benutzer',
                requiresAuth: true,
                requiredRole: [Roles.admin, Roles.superAdmin],
              },
            },
            {
              path: 'user/add',
              component: AdminUserAdd,
              name: 'Benutzer erstellen',
              alias: 'BenutzerErstellen',
              meta: {
                action: 'add',
                active: 'Benutzer',
                requiresAuth: true,
                requiredRole: [Roles.admin, Roles.superAdmin],
              },
            },
            {
              path: 'user/:id/edit',
              component: AdminUserAdd,
              name: 'Benutzer bearbeiten',
              alias: 'BenutzerBearbeiten/:id',
              meta: {
                action: 'edit',
                active: 'Benutzer',
                requiresAuth: true,
                requiredRole: [Roles.admin, Roles.superAdmin],
              },
            },
            {
              path: 'tenant',
              component: AdminTenantList,
              name: 'Tenant',
              alias: 'Tenant',
              meta: {
                active: 'Tenant',
                requiresAuth: true,
                requiredRole: [Roles.superAdmin],
              },
            },
            {
              path: 'tenant/add',
              component: AdminTenantAdd,
              name: 'Tenant erstellen',
              alias: 'TenantErstellen',
              meta: {
                action: 'add',
                active: 'Tenants',
                requiresAuth: true,
                requiredRole: [Roles.superAdmin],
              },
            },
            {
              path: 'tenant/:id/edit',
              component: AdminTenantAdd,
              name: 'Tenant bearbeiten',
              alias: 'TenantBearbeiten/:id',
              meta: {
                action: 'edit',
                active: 'Tenants',
                requiresAuth: true,
                requiredRole: [Roles.superAdmin],
              },
            },
            {
              path: 'branch',
              component: AdminBranchList,
              name: 'Filialen',
              alias: 'Filialen',
              meta: {
                active: 'Filialen',
                requiresAuth: true,
                requiredRole: [Roles.admin, Roles.superAdmin],
              },
            },
            {
              path: 'branch/add',
              component: AdminBranchAdd,
              name: 'Filiale erstellen',
              alias: 'FilialenErstellen',
              meta: {
                action: 'add',
                active: 'Filialen',
                requiresAuth: true,
                requiredRole: [Roles.admin, Roles.superAdmin],
              },
            },
            {
              path: 'branch/:id/edit',
              component: AdminBranchAdd,
              name: 'Filiale bearbeiten',
              alias: 'FilialeBearbeiten/:id',
              meta: {
                action: 'edit',
                active: 'Filialen',
                requiresAuth: true,
                requiredRole: [Roles.admin, Roles.superAdmin],
              },
            },
            {
              path: 'snippet',
              component: AdminSnippetList,
              name: 'Textbausteine',
              alias: 'AdminSnippet',
              meta: {
                active: 'Textbausteine',
                requiresAuth: true,
                requiredRole: [Roles.admin, Roles.superAdmin],
              },
            },
            {
              path: 'snippet/add',
              component: AdminSnippetAdd,
              name: 'Textbausteine erstellen',
              alias: 'AdminSnippetAdd',
              meta: {
                action: 'add',
                active: 'Textbausteine',
                requiresAuth: true,
                requiredRole: [Roles.admin, Roles.superAdmin],
              },
            },
            {
              path: 'snippet/edit/:id',
              component: AdminSnippetAdd,
              name: 'Textbausteine bearbeiten',
              alias: 'AdminSnippetEdit/:id',
              meta: {
                action: 'edit',
                active: 'Textbausteine',
                requiresAuth: true,
                requiredRole: [Roles.admin, Roles.superAdmin],
              },
            },
          ],
        },
      ],
    },
    {
      path: '/login',
      component: LoginView,
      name: 'login',
      beforeEnter: loginRediectGuard,
    },
    {
      path: '/callback/:type',
      component: CallbackView,
      name: 'callback',
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/join/:id',
      component: JoinView,
      name: 'join',
      meta: {
        requiresAuth: false,
      },
    },
  ],
});
// implement the authGuard
router.beforeEach(authGuard);

export default router;
